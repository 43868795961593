import React, {FC} from 'react';
import {IDSIconInformation, IDSPopover, IDSPopoverContent} from "@inera/ids-react";
import {getHelpTextDisplay} from "../../../../services/localstorageconcepts/HelpTextService";

let renderCount = 0;

interface HelpTextPopOverProps {
    code: string
}

export const HelpTextPopOver: FC<HelpTextPopOverProps> = ({
                                                              code
                                                          }) => {
    renderCount++;

    return (<>
            &nbsp;
            <IDSPopover position="bottom">
                &nbsp;&nbsp; <IDSIconInformation height="1.3rem" width="1.3rem"/>
                <IDSPopoverContent maxheight={260} maxwidth={260}>
                    <p className="ids-body">
                        {getHelpTextDisplay(code)}
                    </p>
                </IDSPopoverContent>
            </IDSPopover>
        </>
    );
};

export default HelpTextPopOver;