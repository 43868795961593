import React, {FC} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import {IDSButtonGroup, IDSCol, IDSContainer, IDSDialog, IDSLink, IDSRow} from "@inera/ids-react";
import TTBaseDialog from "../../../common/page/dialog/TTBaseDialog";


interface ValueSetWarnStatusDialogProps {
    showDialog: any,
    dialogRef: any,
    setShowDialog: any
}

export const ValueSetWarnStatusDialog: FC<ValueSetWarnStatusDialogProps> = ({
                                                                                showDialog,
                                                                                dialogRef,
                                                                                setShowDialog
                                                                            }) => {
    const getHeadline = () => {
        return "Aktivering av urvalet är inte möjligt, en eller flera kodverk är ej aktiva"
    }

    const getContent = () => {
        return (
            <div style={{width: "100%"}}>
                <IDSContainer gutterless={true} style={{width: "100%"}}>
                    <IDSRow justify="space-between">
                        <IDSCol>
                            <div className="container">
                                För att aktivera behöver alla källor som artefakten bygger på vara aktiva.
                                <br/> <br/>
                            </div>
                        </IDSCol>
                    </IDSRow>
                    <IDSRow justify="space-between">
                        <IDSCol>
                            <div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <IDSButtonGroup>
                                        <IDSLink underlined>
                                            <a href="#" onClick={() => {
                                                setShowDialog(false);
                                            }}>STÄNG </a>
                                        </IDSLink>
                                    </IDSButtonGroup>
                                </div>
                            </div>
                        </IDSCol>
                    </IDSRow>
                </IDSContainer>
            </div>);
    }

    return (
        <div>
            <TTBaseDialog dialogRef={dialogRef}
                          setShowDialog={setShowDialog}
                          showDialog={showDialog}
                          content={getContent()}
                          headingText={getHeadline()}/>
        </div>
    );
};
export default ValueSetWarnStatusDialog;