import React, {FC} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import CreateNewVersionDialogForm from "./CreateNewVersionDialogForm";
import TTBaseDialog from "../dialog/TTBaseDialog";
import {getArtifactSwedishLabel} from "../../CommonFunctions";


interface CreateNewVersionDialogProps {
    setShowCreateDialog: any,
    artifactType: string,
    data: any,
    setFoundHistory: any,
    showCreateDialog: boolean,
    createButtonRef: any
}

export const CreateNewVersionDialog: FC<CreateNewVersionDialogProps> = ({
                                                                            setShowCreateDialog,
                                                                            artifactType,
                                                                            data,
                                                                            setFoundHistory,
                                                                            showCreateDialog,
                                                                            createButtonRef
                                                    }) => {

    const closeDialog = () => setShowCreateDialog(false);

    return (
        <div>
            <TTBaseDialog dialogRef={createButtonRef}
                          setShowDialog={setShowCreateDialog}
                          showDialog={showCreateDialog}
                          content={<CreateNewVersionDialogForm
                              artifactType={artifactType}
                              closeDialog={closeDialog}
                              data={data}
                              title={data.title}
                              name={data.name}
                              url={data.url}
                              setFoundHistory={setFoundHistory}/>}
                          headingText={"Skapa " + getArtifactSwedishLabel(artifactType)} />
        </div>
    );
};
export default CreateNewVersionDialog;

