import React from "react";
import {IDSIconWarning} from "@inera/ids-react";
import {FC} from "react";


let renderCount = 0;

interface ErrorIconOptionalMessageProps {
    text?: any,
    slotValue?: string,
}

const IconErrorMessage: FC<ErrorIconOptionalMessageProps> = ({
                                                                         text,
                                                                         slotValue
                                                                     }: ErrorIconOptionalMessageProps) => {
    renderCount++;

    return (
        <table>
            <tbody>
            <tr>
                <td style={{backgroundColor: "#f9dbd8"}}>
                    <IDSIconWarning
                        color={"#FF0000"} color2={"#FF0000"}
                        size={"s"}  slot={slotValue}/>
                </td>
                {text !== undefined &&
                    <td>
                        {text}
                    </td>
                }
            </tr>
            </tbody>
        </table>
    );
}

export default IconErrorMessage;
