import React from "react";

import {
    IDSCol,
    IDSContainer,
    IDSRow
} from "@inera/ids-react";
import {ENV_TYPE, IDP_URL_VALUE, ROBOTS_CONTENT, SNOMED_CT_ID_COMMA_SEPERATED_STRING} from "../constants";


const Meta = () => {

    const origin = window.location.origin;
    const url = window.location.href;
    const hostname = window.location.hostname;
    const HOST = process.env.HOST


    return (
        <div>
            <div>
                <IDSContainer gutterless={true}>
                    <IDSRow>
                        <IDSCol>
                            We are currently using react version{" "}
                            {React.version}
                            <br/><br/>
                            <br/>
                            ENV_TYPE : {ENV_TYPE}
                            <br/>
                            IDP_URL : {IDP_URL_VALUE}
                            <br/>
                            ROBOTS_CONTENT : {ROBOTS_CONTENT}
                            <br/>
                            SNOMED_CT_ID_COMMA_SEPERATED_STRING : {SNOMED_CT_ID_COMMA_SEPERATED_STRING}
                            <br/>
                            <br/>url : {url}
                            <br/>
                            <br/>hostname : {hostname}
                            <br/>
                            <br/>origin : {origin}
                            <br/>
                            HOST : {HOST}
                        </IDSCol>
                    </IDSRow>
                </IDSContainer>
            </div>
        </div>
    );

};

export default Meta;
