import React from "react";
import {getSwedishStyledStatusText, valuesetChoosenCodeTableHeaderRow} from "../../../../styles/TTStyles";
import {formatDate, getArtifactSwedishLabel} from "../../../common/CommonFunctions";
import "@inera/ids-design/components/table/table.css";
import {IDSIconArrow, IDSLink} from "@inera/ids-react";

let renderCount = 0;


const RelatedArtifacts = (props) => {

    const getHeader = () => {
        if (props.header) {
            return (<h2 className="ids-heading-m">{props.header}</h2>);
        }
    }

    const getRef = (id) => {
        return (
            <IDSLink>
                <a href={"/" + props.type + "/" + id}>
                    <IDSIconArrow height="0.625rem" width="0.635rem"/>
                    Visa {getArtifactSwedishLabel(props.type).toLowerCase()}
                </a>
            </IDSLink>
        );
    }

    renderCount++;

    return (

        <div className={'container'}>
            <br/><br/>
            {getHeader()}
            <h2 className="ids-heading-m">{props.title}</h2>
            <br/>
            <table className="ids-table" width="100%">
                <tbody>
                <tr style={valuesetChoosenCodeTableHeaderRow}>
                    <th style={{width: "30%", backgroundColor: "#e7f7ea"}}>Titel</th>
                    <th style={{width: "30%", backgroundColor: "#e7f7ea"}}>Version</th>
                    <th style={{width: "15%", backgroundColor: "#e7f7ea"}}>Datum och tid</th>
                    <th style={{width: "10%", backgroundColor: "#e7f7ea"}}>Status</th>
                    <th style={{width: "15%", backgroundColor: "#e7f7ea"}}></th>
                </tr>
                {props.artifactArray?.map((artifact, index) => (
                    <tr key={index}>
                        <td width="30%" style={{wordWrap: "break-word"}}>{artifact.resource?.title}</td>
                        <td width="10%" style={{wordWrap: "break-word"}}>{artifact.resource?.version}</td>
                        <td width="15%" style={{wordWrap: "break-word"}}>{formatDate(artifact.resource?.meta?.lastUpdated)}</td>
                        <td width="15%" style={{wordWrap: "break-word"}}><strong>{getSwedishStyledStatusText(artifact.resource?.status)}</strong></td>
                        <td width="30%" style={{wordWrap: "break-word"}}>{getRef(artifact.resource?.id)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <br/>
            <br/>
        </div>
    );

}
export default RelatedArtifacts;