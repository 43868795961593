import React, {FC, useEffect, useState} from 'react';
import '@inera/ids-core/components/link/register';

import 'beautiful-react-diagrams/styles.css';

import {ValueSet} from "fhir-typescript-models/dist/src/models/fhir/internal";
import ConceptSnomedHierarchy from "./ConceptSnomedHierarchy";
import {getDataWithParentChildDirection} from "../../../../codesystem/snomed/expand/SnomedExpandUtil";
import IConceptSearchResultItem from "../../../../codesystem/snomed/search/model/IConceptSearchResultItem";
import {snomedTaxonomiMenu} from "../../../../constants/PanelConstant";

let renderCount = 0;

interface ConceptSnomedTaxonomiProps {
    id: string,
    version: string,
    snomedConceptsToAdd?: any[],
    parentChildDirectionData?: ValueSet,
    handleTaxonomyNodeClick: any,
    handleChange?: any,
    tabKey?: number,
    codeSystemIndex?: number,
    onChangeFromMenu?: string
}

const ConceptSnomedTaxonomi: FC<ConceptSnomedTaxonomiProps> = ({
                                                                   id,
                                                                   version,
                                                                   snomedConceptsToAdd,
                                                                   parentChildDirectionData,
                                                                   handleTaxonomyNodeClick,
                                                                   handleChange,
                                                                   tabKey,
                                                                   codeSystemIndex,
                                                                   onChangeFromMenu
                                                               }) => {

    const [conceptCollection, setConceptCollection] = useState<IConceptSearchResultItem[]>([]);
    const [loadingRows, setLoadingRows] = useState(false);
    const thisMenuName = snomedTaxonomiMenu;


    useEffect(() => {
        if (parentChildDirectionData !== undefined) {
            getDataWithParentChildDirection(parentChildDirectionData, setConceptCollection);
        }
    }, [parentChildDirectionData]);

    renderCount++;

    return (
        <div>
            <ConceptSnomedHierarchy
                id={id}
                version={version}
                menuName={thisMenuName}
                conceptCollection={conceptCollection}
                setConceptCollection={setConceptCollection}
                loadingRows={loadingRows}
                setLoadingRows={setLoadingRows}
                snomedConceptsToAdd={snomedConceptsToAdd}
                handleTaxonomyNodeClick={handleTaxonomyNodeClick}
                handleChange={handleChange}
                tabKey={tabKey}
                codeSystemIndex={codeSystemIndex}
                changeFromMenu={onChangeFromMenu}
            />
        </div>
    );
};

export default ConceptSnomedTaxonomi;