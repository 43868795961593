import React from 'react';

import {defaultCodeSystemForValueSetArray} from "../../../model/defaultvalues/ValueSetDefaultFormValue";
import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";
import VSIncludeHeading from "./read/VSIncludeHeading";
import VSIncludeCodeSummaryTable from "./VSIncludeCodeSummaryTable";
import {getCodeSystemMeta} from "./util/CSBundleEntryArrayUtil";
import {versionCurrentStyle} from "../../../styles/TTStyles";
import {composeIncludeObjPath} from "./VSConstant";
import {hasSelectedCodesFromCodeSystemToRemove} from "./util/VSCrudFunctions";

let renderCount = 0;

const VSIncludeCodeSummary = (props: any) => {

    const getFromArtifactArray = (system: string, version : string) => {
        for (let artefact of props.artifactArray) {
            if (artefact.resource.url === system && artefact.resource.version === version) {
                return artefact;
            }
        }
    }

    renderCount++;

    return (
        <div>
            {(props.codeSystemsWithAllCodesSelectableCodes !== undefined &&
                    props.codeSystemsWithAllCodesSelectableCodes.length !== 0 &&
                    (JSON.stringify(props.codeSystemsWithAllCodesSelectableCodes)
                        !== JSON.stringify(defaultCodeSystemForValueSetArray))) &&
                <div>
                    <h4 className="ids-heading-xs"
                        style={versionCurrentStyle}>Sammanställning av urval</h4>
                    <div>
                        <br/>
                        {props.conceptsToAdd.map((
                            // @ts-ignore
                            include, index) => {
                            return (
                                <div>
                                    {(props.conceptsToAdd[index].concept === undefined || props.conceptsToAdd[index].concept.length == 0) ?
                                        (<div></div>)
                                        : (<div>
                                            <IDSContainer gutterless={true}>
                                                <IDSRow justify="space-between">
                                                    <IDSCol cols="12">
                                                        <VSIncludeHeading showSystemUrl={false}
                                                                          includeMetaData={props.artifactArray === undefined ? undefined : getCodeSystemMeta(getFromArtifactArray(props.conceptsToAdd[index].system, props.conceptsToAdd[index].version))}/>
                                                    </IDSCol>
                                                </IDSRow>
                                                <IDSRow justify="space-between">
                                                    <IDSCol cols="12">

                                                        <VSIncludeCodeSummaryTable
                                                            index={index}
                                                            conceptsToAdd={props.conceptsToAdd}
                                                            /*        include={include}*/
                                                            removeConceptToValueSet={props.removeConceptToValueSet}/>

                                                    </IDSCol>
                                                </IDSRow>
                                            </IDSContainer>
                                        </div>)
                                    }
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </div>
    );
};

export default VSIncludeCodeSummary;
