import React, {FC} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import TTBaseDialog from "../dialog/TTBaseDialog";
import StopEditDialogContent from "./StopEditDialogContent";

interface StopEditDialogProps {
    setShowDialog: any,
    showDialog: boolean,
    dialogRef: any,
    changeEditDisabled: any
}


export const StopEditDialog: FC<StopEditDialogProps> = ({
                                                            setShowDialog,
                                                            showDialog,
                                                            dialogRef,
                                                            changeEditDisabled,
                                                        }) => {


    const getHeadline = () => {
        return "Ändringar har inte sparats"
    }
    return (
        <div>
            <TTBaseDialog dialogRef={dialogRef}
                          setShowDialog={setShowDialog}
                          showDialog={showDialog}
                          content={<StopEditDialogContent setShowDialog={setShowDialog}
                                                          changeEditDisabled={changeEditDisabled}/>}
                          headingText={getHeadline()}/>
        </div>
    );
};
export default StopEditDialog;