import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {
    MaterialReactTable,
    MaterialReactTableProps,
    MRT_ColumnDef, MRT_RowSelectionState,
    MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiSelectCheckboxPropsStyle,
    muiTableBodyColorPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsThreeColumnStyle,
} from "../../../../styles/TTStyles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import {Tooltip} from '@material-ui/core';
import '@inera/ids-core/components/link/register';

import {
    ICodeSystemConcept, IValueSetComposeIncludeConcept,
    ValueSet
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {
    SNOMED_CT_URL,
} from "../../../../model/defaultvalues/Constant";
import {setObjectKeyValue} from "../../../common/CommonFunctions";
import {getConcepts, requestParent} from "../../../../services/snomed/SnomedService";
import IConceptSearchResultItem from "../../../../codesystem/snomed/search/model/IConceptSearchResultItem";
import {compare} from "../../../../codesystem/snomed/util/ConceptSearchItemUtil";
import {isDataColumn} from "../../../common/mrtable/MRTUtil";
import {getConceptChildHierarchyPath, getConceptInHierarchy} from "./ConceptHierarchyUtil";
import {
    syncRowSelectionWithConceptsToAdd,
    syncRowSelectionWithConceptsToAddEvent
} from "../../../common/mrtable/CheckboxSelectUtil";
import {activeColumn, codeColumn, displayColumn} from "./ConceptColumns";
import { collectContains } from '../../../../codesystem/snomed/transformer/SnomedTransformer';

let renderCount = 0;

interface ConceptSnomedHierarchyProps {
    id: string,
    version: string
    menuName: string,
    snomedConceptsToAdd?: Array<IValueSetComposeIncludeConcept>,
    conceptCollection: IConceptSearchResultItem[],
    setConceptCollection: any,
    loadingRows: boolean,
    setLoadingRows: any,
    handleTaxonomyNodeClick: any,
    handleChange?: any,
    tabKey?: number,
    codeSystemIndex?: number,
    changeFromMenu?: string,
    isChangeCodeSameAsSelected?: boolean
}


const ConceptSnomedHierarchy: FC<ConceptSnomedHierarchyProps> = ({
                                                                     id,
                                                                     version,
                                                                     menuName,
                                                                     snomedConceptsToAdd,
                                                                     conceptCollection,
                                                                     setConceptCollection,
                                                                     loadingRows,
                                                                     setLoadingRows,
                                                                     handleTaxonomyNodeClick,
                                                                     handleChange,
                                                                     tabKey,
                                                                     codeSystemIndex,
                                                                     changeFromMenu,
                                                                     isChangeCodeSameAsSelected
                                                                 }) => {


    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const isCreateValueSetMode = tabKey !== undefined;
    const enableRowSelection = isCreateValueSetMode;

    const reloadTableOk = () => {
        if (isChangeCodeSameAsSelected === undefined) {
            return true
        } else if (isChangeCodeSameAsSelected == false) {
            return true;
        } else {
            return false;
        }
    }


    const setRowSelectionViaNodesPathNumberArray = (nodesPath: number[]) => {
        if (nodesPath.length > 0) {
            let rowSelectionPath = "";
            for (const num of nodesPath) {
                rowSelectionPath = rowSelectionPath === "" ? String(num) : (rowSelectionPath + "." + String(num));
            }
            rowSelection[rowSelectionPath] = true;
        }
    }

    const setRowSelectionFalseOnRowIndexStartsWith = async (nodesPath: number[]) => {
        if (Object.keys(rowSelection).length > 0 && (nodesPath.length > 0)) {
            let rowSelectionSearchString = "";
            for (const num of nodesPath) {
                rowSelectionSearchString = rowSelectionSearchString === "" ? String(num) : (rowSelectionSearchString + "." + String(num));
            }

            for (let key in rowSelection) {
                if (key.startsWith(rowSelectionSearchString)) {
                    rowSelection[key] = false;
                }
            }
        }
    }

    const snomedConceptsToAddString = JSON.stringify(snomedConceptsToAdd);
    const conceptCollectionString = JSON.stringify(conceptCollection);

    useEffect(() => {
        syncRowSelectionWithConceptsToAddEvent(menuName,
            changeFromMenu,
            reloadTableOk(),
            conceptCollection,
            snomedConceptsToAdd,
            rowSelection,
            setRowSelection);
    }, [snomedConceptsToAddString]);

    useEffect(() => {

        syncRowSelectionWithConceptsToAdd(menuName,
            changeFromMenu,
            reloadTableOk(),
            conceptCollection,
            snomedConceptsToAdd,
            rowSelection,
            setRowSelection);
    }, [conceptCollectionString]);


    // @ts-ignore
    const getSubConcepts = async (index: string) => {
        const indexStringArray = index.split(".");
        const indexNumberArray = indexStringArray.map((str) => Number(str));

        const selectedConcept = getConceptInHierarchy(index, conceptCollection);
        const responseValueSet: ValueSet = await requestParent(selectedConcept.code, selectedConcept.display, "parent", selectedConcept.code, version);
        const subRowConcepts = collectContains(responseValueSet)
        
        if (subRowConcepts !== undefined && subRowConcepts.length > 0) {
            subRowConcepts.sort(compare);

            var array = [...conceptCollection]; // make a separate copy of the array
            if (indexNumberArray.length == 1) {
                array[indexNumberArray[0]].concept = subRowConcepts;
            } else {
                let conceptHierarchyPath = getConceptChildHierarchyPath(indexNumberArray.slice(1) // all except first obj in array
                );
                setObjectKeyValue(array[indexNumberArray[0]], conceptHierarchyPath, subRowConcepts)
            }

            //Collect if code is selected
            // @ts-ignore
            const objInRowSelection = rowSelection[indexStringArray];
            const codeSelected = objInRowSelection !== undefined && objInRowSelection;

            //remove and replace existing selections on child hiarchy level as the sorting on  display-name change the row selection order
            await setRowSelectionFalseOnRowIndexStartsWith(indexNumberArray);

            //After remove set code if selected
            if (codeSelected) {
                // @ts-ignore
                rowSelection[indexStringArray] = true;
            }

            //Set selection on subrows if found in "snomedConceptsToAdd" array
            if (snomedConceptsToAdd !== undefined && snomedConceptsToAdd.length > 0) {
                for (var i = 0; i < snomedConceptsToAdd.length; i++) {

                    const codeConceptToAdd = snomedConceptsToAdd[i].code;
                    const index: number = subRowConcepts.findIndex(subRow => subRow.code === codeConceptToAdd);
                    if (index != -1) {
                        let indexNumberArrayForSelection = [...indexNumberArray]; // make a separate copy of the array
                        indexNumberArrayForSelection.push(index);
                        setRowSelectionViaNodesPathNumberArray(indexNumberArrayForSelection);
                    }
                }
            }
            setConceptCollection(array)
        }
    }


    renderCount++;

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        codeColumn,
        displayColumn,
        activeColumn(' ')
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );

    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);
    let count = 0;

    const handleRowClick = async (code: string, rowindex: string) => {
        const selectedConcept = getConceptInHierarchy(rowindex, conceptCollection);
        handleTaxonomyNodeClick(selectedConcept, version)
    }

    renderCount++;

    return (
        <div>
            <div>
                <>
                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        data={conceptCollection}
                        enableRowOrdering={false}
                        enableColumnFilters={false}
                        enableSorting={false}
                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        /*      layoutMode='grid'*/

                        enableColumnResizing={false}
                        /*      columnResizeDirection=rtl'*/

                        enableSelectAll={false}
                        enableSubRowSelection={false}
                        enableRowSelection={enableRowSelection}
                        onRowSelectionChange={setRowSelection}
                        enableRowActions={false}
                        enableCellActions={false}
                        enableColumnActions={false}
                        enableMultiRowSelection={true}

                        enableEditing={false}
                        enableHiding={false}
                        editDisplayMode="table"

                        /*     positionActionsColumn="last"*/
                        enableExpanding
                        enableExpandAll={false}
                        paginateExpandedRows={false}
                        state={{
                            rowSelection,
                            isLoading: loadingRows
                        }}
                        // @ts-ignore
                        /*         getRowId={(originalRow) => originalRow.code}*/
                        initialState={{
                            pagination: {pageSize: 50, pageIndex: 0},
                            /*      showColumnFilters: true*/
                            /*              , expanded: initialExpandedRootRows*/
                        }}

                        columnFilterDisplayMode='popover'

                        muiSelectCheckboxProps={({row, table}) => ({
                            // @ts-ignore
                            defaultChecked: row.original.selected,
                            // @ts-ignore
                            disabled: row.original.inactive,
                            onClick: (event) => {
                                const rowId = row.id;
                                // @ts-ignore
                                row.original.codeSystemConceptIndex = rowId;
                                const clone = JSON.parse(JSON.stringify(row.original));
                                clone.concept = undefined;
                                handleChange(event, clone, SNOMED_CT_URL, version, rowId, codeSystemIndex, id, menuName);

                                // @ts-ignore
                                /*                if (event.target.checked) {
                                                    // @ts-ignore
                                                    handleRowClick(code, rowId);
                                                }*/
                            },
                            sx: muiSelectCheckboxPropsStyle
                        })}


                        muiExpandButtonProps={({row}) => ({
                            onClick: () => {
                                if (row.original.code !== "138875005" && row.getIsExpanded() === false) {
                                    // @ts-ignore
                                    getSubConcepts(row.id)
                                }
                            }
                        })}


                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsThreeColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyColorPropsStyle
                        }}

                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}


                        muiTableHeadCellProps={{
                            sx: {
                                /*              border: '1px solid rgba(81, 81, 81, .5)',
                                              fontStyle: 'italic',*/
                                fontWeight: 'bold',
                            },
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            /*          'mrt-row-actions': {
                                          header: '',
                                          size: 10,
                                      },*/
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-expand': {
                                size: 5,
                                Header: (
                                    <Tooltip title={'Expandera'}>
                                        <span>{"Exp."}</span>
                                    </Tooltip>
                                ),


                            },
                            'mrt-row-select': {
                                size: 8,
                            },
                        }}

                        // @ts-ignore
                        getSubRows={(originalRow, index) => {
                            return (
                                originalRow.concept
                            )
                        }}


                        /*           muiColumnActionsButtonProps={{
                                       children: <MoreVertIcon/>
                                   }}
           */

                        muiTableBodyCellProps={({cell, row, table}) => ({
                            sx: {
                                cursor: isDataColumn(cell) ? 'pointer' : 'default',
                                verticalAlign: 'top',
                                /*            border: '1px solid rgba(81, 81, 81, .5)'*/
                            },
                            onClick: () => {
                                if (isDataColumn(cell)) {
                                    const code = row.original.code;
                                    const display = row.original.display;
                                    // @ts-ignore
                                    handleRowClick(code, row.id);
                                }
                            },
                        })}
                    />
                </>
            </div>
        </div>
    );
};

export default ConceptSnomedHierarchy;
