import React from "react";
import {IDSAlert } from "@inera/ids-react";
import {FC} from "react";


let renderCount = 0;

interface FormFieldInfoMessageProps {
    text: string;
}

const FormFieldInfoMessage: FC<FormFieldInfoMessageProps> = ({
                                               text
                                           }: FormFieldInfoMessageProps) => {
    renderCount++;

    return (
        <IDSAlert
            dismissible={false}
            ribbon
            // @ts-ignore
            srIconTitle="Information"
            type="info"
        >
            {text}
        </IDSAlert>
    );
}

export default FormFieldInfoMessage;
