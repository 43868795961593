// @ts-nocheck

import React, {FC} from 'react';
import {UseFormReturn} from 'react-hook-form';
import RHookFormTextFieldController from "./RHookFormTextFieldController";
import ITabName from "../page/ITabName";
import {handlePerformedValidationAfterNewContent} from "../validation/RHookFormValidationUtil";
import {handleKeyUp} from "./FieldUtil";

interface ReactHookFormTextFieldIneraProps {
    label: string;
    name: string;
    tabName?: ITabName,
    rowindex?: string,
    editDisabled?: boolean,
    methods?: UseFormReturn;
    onChangeFunction?: any;
    warnFunction?: any;
    handleWarnMessageList?: any,
    helpTextCode?: string
}

let renderCount = 0;
const RHookFormTextFieldForExtension: FC<ReactHookFormTextFieldIneraProps> = ({
                                                                                  label,
                                                                                  name,
                                                                                  tabName,
                                                                                  rowindex,
                                                                                  editDisabled,
                                                                                  methods,
                                                                                  onChangeFunction,
                                                                                  warnFunction,
                                                                                  handleWarnMessageList,
                                                                                  helpTextCode
                                                                              }: ReactHookFormTextFieldIneraProps) => {

    renderCount++;


    const getOnChangeFunction = (e: any) => {
        if (tabName !== undefined) {
            handlePerformedValidationAfterNewContent(e, name, tabName, methods,
                undefined, handleWarnMessageList, undefined);
        }

        if (e !== undefined && onChangeFunction !== undefined) {
            onChangeFunction(e);
        }
    }

    return (
        <div onBlur={getOnChangeFunction}>
            {/*        <div
            onKeyUp={e => handleKeyUp(e, getOnChangeFunction)}>*/}
            <RHookFormTextFieldController methods={methods}
                                          editDisabled={editDisabled}
                                          label={label}
                                          name={name}
                                          tabName={tabName}
                                          rowindex={rowindex}
                                          handleWarnMessageList={handleWarnMessageList}
                                          warn={(value: string) => (warnFunction === undefined ? undefined : warnFunction(value, rowindex))}
                                          helpTextCode={helpTextCode}
            />
            <br/>
        </div>
    );
};

export default RHookFormTextFieldForExtension;