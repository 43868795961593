import * as React from "react";
import RHookFormWarnInfoController from "./RHookFormWarnInfoController";
import {IDSErrorMessage, IDSInput} from "@inera/ids-react";
import {FC, useEffect, useState} from "react";
import RHookFormValidationMessage from "../validation/RHookFormValidationMessage";
import ITabName from "../page/ITabName";
import {
    removeValidationInternalMetaDataWarningFromValidationCollectionList
} from "../validation/ValidationInternalMetaDataWarningRulesUtil";
import {
    collectValidationInternalMetaDataYupErrorRules
} from "../validation/ValidationInternalMetaDataYupErrorRulesUtil";
import {removeTabsInValue} from "./FieldUtil";
import {getHelpTextPopOverIfAny} from "../../../services/localstorageconcepts/HelpTextService";
import {getIDSInputInvalidValue} from "../validation/RHookFormValidationUtil";
import FormFieldWarnMessage from "../validation/message/FormFieldWarnMessage";
import FormFieldInfoMessage from "../validation/message/FormFieldInfoMessage";

interface RHookFormTextFieldControllerProps {
    name: string,
    label?: string,
    tabName?: ITabName,
    methods: any,
    warn?: any,
    info?: any,
    toucherror?: any,
    handleWarnMessageList?: any
    editDisabled: boolean,
    rowindex?: string,
    helpTextCode?: string,
}

const RHookFormTextFieldController: FC<RHookFormTextFieldControllerProps> = ({
                                                                                 name,
                                                                                 label,
                                                                                 tabName,
                                                                                 methods,
                                                                                 warn,
                                                                                 info,
                                                                                 toucherror,
                                                                                 handleWarnMessageList,
                                                                                 editDisabled,
                                                                                 rowindex,
                                                                                 helpTextCode
                                                                             }) => {


    const [previousYupValidation, setPreviousYupValidation] = useState<string>();
    const arrayName = rowindex === undefined ? "" : name.substring(0, name.indexOf("."));

    useEffect(() => {
        if (tabName !== undefined) {
            collectValidationInternalMetaDataYupErrorRules(name, previousYupValidation, setPreviousYupValidation, handleWarnMessageList, methods, tabName, rowindex);
            removeValidationInternalMetaDataWarningFromValidationCollectionList(name, handleWarnMessageList, methods, tabName, undefined)
        }
        //methods.formState.errors?.['identifier']?.[0]?.['value']?.['message']

    }, [methods.formState.errors[name], methods.formState.errors[arrayName]]);


    return (
        <RHookFormWarnInfoController
            control={methods.control}
            name={name}
            defaultValue=""
            warn={warn !== undefined ? warn : undefined}
            info={info !== undefined ? info : undefined}
            toucherror={toucherror !== undefined ? toucherror : undefined}
            methods={methods}
            render={({
                         // @ts-ignore
                         value, onChange, onBlur, name, isDirty, isTouched, warning, info, toucherror
                     }) => {
                return (
                    <>
                        <div className={'container'} key={"container_textinput_" + name}>
                            <IDSInput invalid={getIDSInputInvalidValue(name, methods)} novalidation>
                                {label === undefined ? <label></label> :
                                    <label>{label}{getHelpTextPopOverIfAny(helpTextCode, editDisabled)}</label>}
                                <input disabled={editDisabled}
                                       {...!editDisabled &&
                                       methods.register(name, {setValueAs: (value: string) => removeTabsInValue(value)})}
                                       {...{value, onChange, onBlur, name}}
                                       key={"textinput_" + name}
                                />
                                {(!!toucherror && isTouched && !editDisabled) &&
                                    <IDSErrorMessage>{toucherror}</IDSErrorMessage>}

                            </IDSInput>
                            <p style={{lineHeight: "50%"}}>
                                <br/>
                                <RHookFormValidationMessage name={name} editDisabled={editDisabled}/>
                                {(!!warning && isTouched && !editDisabled) && <FormFieldWarnMessage text={warning}/>}
                                {!(!!warning && isTouched && !editDisabled) && (warn(methods.getValues(name), name) !== undefined &&
                                        warn(methods.getValues(name), name) !== false && !editDisabled) &&
                                    <FormFieldWarnMessage text={warn(methods.getValues(name), name)}/>}
                                {(!!info && isTouched && !editDisabled) && <FormFieldInfoMessage text={info}/>}
                            </p>
                            <br/>
                        </div>
                    </>
                );
            }}
        />
    );
};
export default RHookFormTextFieldController;