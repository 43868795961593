// @ts-nocheck
import React, {FC, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";
import {getRenderCountText} from '../../common/RenderCount';
import Publisher from "./contact/Publisher";
import Author from "./contact/Author";
import {collectValidationMessage} from "../../common/validation/MetaValidationRules";
import {authNameWarn, descriptionMinimumWarn, publisherNameWarn} from "./MetaWarnInfoConstant";
import Name from "./Name";
import RHookFormTextFieldSpan from "../../common/rhookform/RHookFormTextFieldSpan";
import {metaDataTabName} from "../../common/page/TabName";
import RHookFormTextAreaController from "../../common/rhookform/RHookFormTextAreaController";
import Url from "./Url";
import {HELP_META_BESKRIVNING, HELP_META_SYSTEM_NAME, HELP_META_TITLE} from "../../../constants/HelpTextCodeConstants";
let renderCount = 0;

interface NamePurposeProps {
    artifactType: string,
    editDisabled?: boolean,
    handleInfoMessageList: any,
    handleWarnMessageList: any,
    handleErrorMessageList: any,
    removeValidationMessagesOnPath: any
}

const NamePurpose: FC<NamePurposeProps> = ({
                                               artifactType,
                                               editDisabled,
                                               handleInfoMessageList,
                                               handleWarnMessageList,
                                               handleErrorMessageList,
                                               removeValidationMessagesOnPath
                                           }) => {

    const methods = useFormContext();
    renderCount++;

    const descriptionWarnFunction = (value: string) => {
        let message = "Ge en utförligare beskrivning. Minimum 10 tecken."
        let validationResult = value !== undefined && ((value.trim().length > 0 && value.length < 10) && message);
        return collectValidationMessage(message, descriptionMinimumWarn, validationResult, handleWarnMessageList, metaDataTabName);
    }

    const authorNameValidation = (value: string) => {
        return nameValidation(value, authNameWarn, "innehållsansvarig");
    }

    const publisherNameValidation = (value: string) => {
        return nameValidation(value, publisherNameWarn, "förvaltare");
    }

    const nameValidation = (value: string, validationlabel: string, roll: string) => {
        let message = "Ange " + roll + ". Minst 2 tecken."
        let validationResult = value !== undefined && ((value.trim().length > 0 && value.trim().length < 2) && message);
        return collectValidationMessage(message, validationlabel, validationResult, handleWarnMessageList, metaDataTabName);
    }

    return (

        <div>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        <br/>
                        <h2 className="ids-heading-m">Titel och systemnamn</h2>
                        <br/>
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">

                        <RHookFormTextFieldSpan label="Titel (obligatorisk)" name="title"
                                                handleErrorMessageList={handleErrorMessageList}
                                                handleInfoMessageList={handleInfoMessageList}
                                                tabName={metaDataTabName} disabled={editDisabled}
                                                helpTextCode={HELP_META_TITLE}/>

                    </IDSCol>
                    <IDSCol cols="6">
                        <Name artifactType={artifactType}
                              handleErrorMessageList={handleErrorMessageList}
                              handleInfoMessageList={handleInfoMessageList}
                              disabled={editDisabled}
                              helpTextCode={HELP_META_SYSTEM_NAME}/>
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">
                        <Url/>
                    </IDSCol>
                    <IDSCol cols="6">
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        <br/>
                        <h2 className="ids-heading-m">Beskrivning och syfte</h2>
                        <br/>
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="6">
                        <RHookFormTextAreaController methods={methods}
                                                     label="Beskrivning och syfte"
                                                     name="description"
                                                     editDisabled={editDisabled}
                                                     warn={descriptionWarnFunction}
                                                     tabName={metaDataTabName}
                                                     maxLength={1000}
                                                     handleWarnMessageList={handleWarnMessageList}
                                                     helpTextCode={HELP_META_BESKRIVNING}/>
                        <br/>
                    </IDSCol>
                    <IDSCol cols="6">
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol cols="12">
                        <h2 className="ids-heading-m">Ansvariga och kontaktuppgifter</h2>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
            <Publisher editDisabled={editDisabled}
                       publisherNameValidation={publisherNameValidation}
                       handleWarnMessageList={handleWarnMessageList}
                       handleErrorMessageList={handleErrorMessageList}
                       removeValidationMessagesOnPath={removeValidationMessagesOnPath}/>
            <Author editDisabled={editDisabled}
                    authorNameValidation={authorNameValidation}
                    handleWarnMessageList={handleWarnMessageList}
                    handleErrorMessageList={handleErrorMessageList}
                    removeValidationMessagesOnPath={removeValidationMessagesOnPath}
            />
        </div>
    );
};

export default NamePurpose;
