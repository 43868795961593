// @ts-nocheck

import React, {FC} from 'react';
import {UseFormReturn} from 'react-hook-form';
import ITabName from "../page/ITabName";
import {handleKeyUp} from "./FieldUtil";
import RHookFormTextAreaController from "./RHookFormTextAreaController";

interface ReactHookFormTextFieldIneraProps {
    label: string;
    name: string;
    tabName?: ITabName;
    editDisabled?: boolean,
    methods?: UseFormReturn;
    onChangeFunction?: any;
    warnFunction?: any,
    maxLength?: number,
    handleWarnMessageList?: any,
    helpTextCode?: string
}

let renderCount = 0;
const RHookFormTextAreaForExtension: FC<ReactHookFormTextFieldIneraProps> = ({
                                                                                 label,
                                                                                 name,
                                                                                 tabName,
                                                                                 editDisabled,
                                                                                 methods,
                                                                                 onChangeFunction,
                                                                                 warnFunction,
                                                                                 maxLength,
                                                                                 handleWarnMessageList,
                                                                                 helpTextCode
                                                                             }: ReactHookFormTextFieldIneraProps) => {

    const {onChange, onBlur, ref} = methods.register(name);

    renderCount++;

    const getOnChangeFunction = (e: any) => {
        if (e !== undefined && onChangeFunction !== undefined) {
            onChangeFunction(e);
        }
    }

    return (
        <div onKeyUp={e => handleKeyUp(e, getOnChangeFunction)}>
            <RHookFormTextAreaController methods={methods}
                                         label={label}
                                         name={name}
                                         editDisabled={editDisabled}
                                         warn={warnFunction}
                                         maxLength={maxLength === undefined ? 256 : maxLength}
                                         tabName={tabName}
                                         handleWarnMessageList={handleWarnMessageList}
                                         helpTextCode={helpTextCode}/>
        </div>
    );
};

export default RHookFormTextAreaForExtension;