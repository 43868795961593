import React, {FC} from 'react';
import TableCellTooltip from "./TableCellTooltip";
import IconInfoMessage from "../../validation/message/IconInfoMessage";

let renderCount = 0;

interface TableCellInfoTooltipProps {
    text: string
}

export const TableCellInfoTooltip: FC<TableCellInfoTooltipProps> = ({
                                                                        text
                                                                    }) => {
    renderCount++;

    return (
        <TableCellTooltip
            text={text}
            title={"Information"}
            idsIcon={
                <IconInfoMessage slotValue={"trigger"}/>
            }
        />
    );
};

export default TableCellInfoTooltip;