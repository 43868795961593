import React from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css'
import {
    IDSFooter, IDSLink, IDSIconExternal, IDSMobileMenu, IDSMobileMenuItem
} from "@inera/ids-react";

export const Footer = () => {
    return (
        <IDSFooter servicename="[Terminologitjänsten]" type="inera-admin">

            <p style={{
                position: 'relative'
            }} slot="sub-footer-right">
                <IDSLink underlined
                    className="ids-mr-1"
                    light
                >
                    <a
                        href="https://inera.atlassian.net/wiki/x/jYB82"
                        style={{
                            fontSize: '0.875rem'
                        }}
                    >
                        Tillgänglighetsredogörelse
                    </a>
                </IDSLink>
            </p>
            <p slot="sub-footer-left">
                {' '}Terminologitjänsten drivs av{' '}
                <IDSLink
                    className="ids-mr-1"
                    light
                >
                    <a
                        href="https://www.inera.se"
                        style={{
                            fontSize: '0.875rem'
                        }}
                    >
                        Inera AB
                        <IDSIconExternal
                            height="0.875rem"
                            inline
                            width="0.875rem"
                        />
                    </a>
                </IDSLink>
            </p>

            {/* mobile*/}
            <p slot="sub-footer-mobile">
                {' '}[Tjänsten] drivs av{' '}
                <IDSLink
                    className="ids-mr-1"
                    light
                >
                    <a
                        href="https://www.inera.se"
                        style={{
                            fontSize: '0.875rem'
                        }}
                    >
                        Inera AB
                        <IDSIconExternal
                            height="0.875rem"
                            inline
                            width="0.875rem"
                        />
                    </a>
                </IDSLink>
            </p>
            <IDSLink slot="sub-footer-links" color="white">
                <a href="https://inera.atlassian.net/wiki/x/jYB82" alt="Tillgänglighetsredogörelse">Tillgänglighetsredogörelse</a>
            </IDSLink>
        </IDSFooter>
    );
};
