import React from "react";
import {sector_codesystem_id, tagSectorSystemUrl} from "../../model/defaultvalues/Constant";
import {SECTORS} from "../../constants";
import {IRHookSelectOption} from "../../components/common/rhookform/IRHookSelectOption";
import {getConceptsFromLocalStorage, loadLocalStorageItems} from "./ConceptLStorageBaseServicel";

export const loadSectors = async () => {
    return await loadLocalStorageItems(sector_codesystem_id, SECTORS);
};

export const getSectorTitleForSearchResultItem = (item: any) => {
    console.log("getSectorTitleForSearchResultItem: " + JSON.stringify(item));
    if (item.resource.meta.tag !== undefined) {
        return getSectorTitleFromMetaTag(item.resource.meta.tag)
    }
};

export const getSectorTitleFromArtefact = (response: any) => {
    if (response.meta.tag !== undefined) {
        return getSectorTitleFromMetaTag(response.meta.tag)
    }
};

export const getSectorTagCodeFromArtefact = (artefact: any) => {
    if (artefact.meta.tag !== undefined) {
        return getSectorTagCode(artefact.meta.tag)
    }
};

export const getSectorTitleFromMetaTag = (tagArray: any) => {

    // @ts-ignore
    var sectors = JSON.parse(localStorage.getItem(SECTORS))

    if (sectors === undefined || (Array.isArray(sectors) && sectors.length == 0)) {
        loadSectors();
        // @ts-ignore
        sectors = JSON.parse(localStorage.getItem(SECTORS))
    }

    let sectorTagCode: any = undefined;
    if (sectors !== undefined && sectors !== null && sectors.length != 0) {
        // @ts-ignore
        sectorTagCode = getSectorTagCode(tagArray);
    }
    if (sectorTagCode !== undefined && sectorTagCode !== null) {
        sectorTagCode = removeLeadingZeros(sectorTagCode);
        // @ts-ignore
        const sector = sectors.find(sector => sector.code === sectorTagCode)

        if (sector !== undefined) {
            return sector.display;
        } else {
            return "";
        }
    } else {
        return "";
    }
};

export const removeLeadingZeros = (sectorCode: string) => {
    if (sectorCode !== undefined && sectorCode !== null) {
        while (sectorCode.startsWith("0") && sectorCode.length > 1) {
            sectorCode = sectorCode.substring(1);
        }
    }
    return sectorCode;
}

const getSectorTagCode = (tagArray: any) => {
    let tag: any = undefined;
    if (tagArray !== undefined && Array.isArray(tagArray)) {
        // @ts-ignore
        tag = tagArray.find(t => t.system.includes(tagSectorSystemUrl))
    }
    try {
        return ((tag === undefined) || (tag.code === undefined)) ? undefined : tag.code;
    } catch (error) {
        console.error(error);
    }
}


const getSectorOptions = (): IRHookSelectOption[] => {

    let sectorArray: any[] = getConceptsFromLocalStorage(sector_codesystem_id, SECTORS);
    let sectorOptions: IRHookSelectOption[] = []
    for (let sector of sectorArray) {
        sectorOptions.push({label: sector.display, value: sector.code})
    }
    return sectorOptions;
};

export const sectorOptions: IRHookSelectOption[] = getSectorOptions();