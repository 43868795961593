import {IDSIconArrow, IDSLink} from "@inera/ids-react";
import '@inera/ids-core/components/expandable/register';
import {getRenderCountText} from '../../../common/RenderCount';
import React, {FC} from "react";
import '@inera/ids-core/components/expandable/register';
import {getSwedishStyledStatusText, versionCurrentStyle} from "../../../../styles/TTStyles";
import IIncludeMeta from "./IIncludeMeta";
import {codesystem} from "../../../../model/defaultvalues/Constant";

let renderCount = 0;


interface VSIncludeHeadingProps {
    includeMetaData?: IIncludeMeta,
    showSystemUrl: boolean
}


const VSIncludeHeading: FC<VSIncludeHeadingProps> = ({includeMetaData, showSystemUrl}) => {

    const getSystemPresentation = () => {
        if (includeMetaData !== undefined && showSystemUrl) {
            return (
                <div>
                    Källa:&nbsp; <IDSLink>
                        <a href={"/" + codesystem + "/" + includeMetaData?.id}>
                            <IDSIconArrow     height="0.625rem"
                                              width="0.635rem" />
                            {includeMetaData.system}
                        </a>
                    </IDSLink> <br/>
                </div>
            );
        } else if (includeMetaData !== undefined && !showSystemUrl) {
            return (
                <div>
                    Källa:&nbsp;
                    {includeMetaData.system}
                    <br/>
                </div>
            );
        }
    }


    renderCount++;

    return (
        <div>
            {(includeMetaData !== undefined && includeMetaData?.system !== undefined) &&
                <div>
                    <h3 className="ids-heading-s"
                        style={versionCurrentStyle}>{includeMetaData.title}</h3>
                    {(
                            ((// @ts-ignore
                                includeMetaData.system) !== undefined)) &&
                        <p className="body">
                            {getSystemPresentation()}
                            Version: <strong>{
                            // @ts-ignore
                            includeMetaData.version}</strong>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <span>Versionsstatus: <strong>{getSwedishStyledStatusText(
                                // @ts-ignore
                                includeMetaData.status)}</strong>
                                                </span>
                        </p>
                    }
                </div>
            }
        </div>
    );
}
export default VSIncludeHeading;