import React, {FC} from 'react';
import TableCellTooltip from "./TableCellTooltip";
import IconErrorMessage from "../../validation/message/IconErrorMessage";

let renderCount = 0;

interface TableCellErrorTooltipProps {
    text: string
}

export const TableCellErrorTooltip: FC<TableCellErrorTooltipProps> = ({
                                                                          text
                                                                      }) => {
    renderCount++;

    return (
        <TableCellTooltip
            text={text}
            title={"Fel"}
            idsIcon={
                <IconErrorMessage slotValue={"trigger"}/>
            }
        />
    );
};

export default TableCellErrorTooltip;