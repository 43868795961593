import React from "react";
import {IDSButton, IDSIconArrow, IDSLink} from "@inera/ids-react";


const SourceTabs = (props) => {

    const changeSource = () => {
        /*  props.initTabSource ? props.setInitTabSource(false): props.setInitTabSource(true)*/

        if (props.initTabSource) {
            props.setInitTabSource(false);
        }

        if (!props.initTabSource) {
            props.setInitTabSource(true);
        }

        props.setSourceTableData([]);
    };

    if (props.initTabSource) {

        return (
            <IDSButton size='s' secondary type="button">
                <button type="button" onClick={() => {
                    props.setInitTabSource(false);
                }}>
                    ANGE KÄLLA
                </button>
            </IDSButton>
        );

    } else if (!props.initTabSource && props.sourceTableData.length > 0) {
        return (
            <div className={'right'}>
                <IDSLink>
                    <a href="#" onClick={() => {
                        changeSource();
                    }}>
                        <IDSIconArrow height="0.625rem" width="0.635rem"/>
                        Ändra källa
                    </a>
                </IDSLink>
            </div>
        );
    } else if (!props.initTabSource) {
        return (
            <IDSButton size='s' secondary type="button">
                <button type="button" onClick={() => {
                    changeSource();
                }}>
                AVBRYT
                </button>
            </IDSButton>
        );
    }
};

export default SourceTabs;
