import React from "react";
import TableCellInfoTooltip from "./TableCellInfoTooltip";
import TableCellErrorTooltip from "./TableCellErrorTooltip";
import TableCellWarningTooltip from "./TableCellWarningTooltip";


export const getTableCellErrorPopOverIfAny = (text: string | undefined) => {
    return (text === undefined) ? "" : <TableCellErrorTooltip text={text}/>
}

export const getTableCellWarningPopOverIfAny = (text: string | undefined) => {
    return (text === undefined) ? "" : <TableCellWarningTooltip text={text}/>
}

export const getTableCellInfoPopOverIfAny = (text: string | undefined) => {
    return (text === undefined) ? "" : <TableCellInfoTooltip text={text}/>
}
