import React, {FC} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import {IDSButton, IDSButtonGroup, IDSCol, IDSContainer, IDSRow} from "@inera/ids-react";


interface StopEditDialogContentProps {
    setShowDialog: any,
    changeEditDisabled: any
}

export const StopEditDialogContent: FC<StopEditDialogContentProps> = ({
                                                                          setShowDialog,
                                                                          changeEditDisabled
                                                                      }) => {

    const getHeadline = () => {
        return "Ändringar har inte sparats"
    }
    return (

        <div style={{width: "100%"}}>
            <IDSContainer gutterless={true} style={{width: "100%"}}>
                <IDSRow justify="space-between">
                    <IDSCol>
                        <div className="container">
                            Om du har gjort redigeringar och avslutar redigeringen utan att spara kommer de
                            ändringar du har gjort försvinna.
                            Om du vill spara ändringar tryck på Avbryt för att komma tillbaks till
                            redigeringsläget.
                            Om du vill fortsätta utan att spara, klicka på sluta redigera.
                            <br/> <br/>
                        </div>
                    </IDSCol>
                </IDSRow>
                <IDSRow justify="space-between">
                    <IDSCol>
                        <div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <IDSButtonGroup>

                                    <IDSButton type="button" secondary size="s">
                                        <button type="button" onClick={() => changeEditDisabled(true)}>
                                            SLUTA REDIGERA UTAN ATT SPARA
                                        </button>
                                    </IDSButton>

                                    <IDSButton type="button" secondary toggle active size="s">
                                        <button type="button" onClick={() => setShowDialog(false)}>
                                            AVBRYT
                                        </button>
                                    </IDSButton>

                                </IDSButtonGroup>
                            </div>
                        </div>
                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>

    );
};
export default StopEditDialogContent;