import React from "react";
import {IDSAlert} from "@inera/ids-react";
import {FC} from "react";


let renderCount = 0;

interface FormFieldWarnMessageProps {
    text: string;
}

const FormFieldWarnMessage: FC<FormFieldWarnMessageProps> = ({
                                               text
                                           }: FormFieldWarnMessageProps) => {
    renderCount++;

    return (
        <IDSAlert
            dismissible={false}
            ribbon
            // @ts-ignore
            srIconTitle="Varning"
            type="attention"
        >
            {text}
        </IDSAlert>
    );
}

export default FormFieldWarnMessage;
