import React, {FC} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import ImportExcelForCodeSystem from "./ImportExcelForCodeSystem";
import {codesystem} from "../../model/defaultvalues/Constant";
import TTBaseDialog from "../../components/common/page/dialog/TTBaseDialog";

interface ImportFromExcelDialogProps {
    setShowImportDialog: any,
    showImportDialog: boolean,
    importButtonRef: any,
    conceptsInCodeSystem: any,
    addCodes: any,
    replaceCodes: any
}

export const ImportFromExcelDialog: FC<ImportFromExcelDialogProps> = ({
                                                                          setShowImportDialog,
                                                                          showImportDialog,
                                                                          importButtonRef,
                                                                          conceptsInCodeSystem,
                                                                          addCodes,
                                                                          replaceCodes
                                                                      }) => {

    const closeDialog = () => {
        setShowImportDialog(false);
    }

    return (
        <div>
            <TTBaseDialog dialogRef={importButtonRef}
                          setShowDialog={setShowImportDialog}
                          showDialog={showImportDialog}
                          content={<ImportExcelForCodeSystem artifactType={codesystem}
                                                             conceptsInCodeSystem={conceptsInCodeSystem}
                                                             addCodes={addCodes}
                                                             replaceCodes={replaceCodes}
                                                             closeDialog={closeDialog}
                                                             key={new Date().getTime()}/>}
                          headingText={"Importera koder från fil"}/>
        </div>
    );
};
export default ImportFromExcelDialog;