import * as React from "react";
import RHookFormWarnInfoController from "./RHookFormWarnInfoController";
import {IDSErrorMessage, IDSTextarea} from "@inera/ids-react";
import {FC, useEffect, useState} from "react";
import RHookFormValidationMessage from "../validation/RHookFormValidationMessage";
import ITabName from "../page/ITabName";
import {
    removeValidationInternalMetaDataWarningFromValidationCollectionList
} from "../validation/ValidationInternalMetaDataWarningRulesUtil";
import {
    collectValidationInternalMetaDataYupErrorRules
} from "../validation/ValidationInternalMetaDataYupErrorRulesUtil";
import {removeTabsInValue} from "./FieldUtil";
import {getHelpTextPopOverIfAny} from "../../../services/localstorageconcepts/HelpTextService";
import FormFieldWarnMessage from "../validation/message/FormFieldWarnMessage";
import FormFieldInfoMessage from "../validation/message/FormFieldInfoMessage";
import {getIDSInputInvalidValue} from "../validation/RHookFormValidationUtil";

interface RHookFormTextFieldControllerAreaProps {
    name: string,
    label?: string,
    tabName?: ITabName,
    methods: any,
    warn?: any,
    info?: any,
    toucherror?: any,
    handleWarnMessageList?: any
    editDisabled: boolean,
    style?: any,
    rowindex?: string,
    maxLength?: number,
    helpTextCode?: string
}

const RHookFormTextAreaController: FC<RHookFormTextFieldControllerAreaProps> = ({
                                                                                    name,
                                                                                    label,
                                                                                    tabName,
                                                                                    methods,
                                                                                    warn,
                                                                                    info,
                                                                                    toucherror,
                                                                                    handleWarnMessageList,
                                                                                    editDisabled,
                                                                                    style,
                                                                                    rowindex,
                                                                                    maxLength,
                                                                                    helpTextCode
                                                                                }) => {


    const [previousYupValidation, setPreviousYupValidation] = useState<string>();
    const arrayName = rowindex === undefined ? "" : name.substring(0, name.indexOf("."));

    useEffect(() => {
        if (tabName !== undefined) {
            collectValidationInternalMetaDataYupErrorRules(name, previousYupValidation, setPreviousYupValidation, handleWarnMessageList, methods, tabName, rowindex);
            removeValidationInternalMetaDataWarningFromValidationCollectionList(name, handleWarnMessageList, methods, tabName, undefined)
        }
    }, [methods.formState.errors[name], methods.formState.errors[arrayName]]);


    return (
        <RHookFormWarnInfoController
            control={methods.control}
            name={name}
            defaultValue=""
            warn={warn !== undefined ? warn : undefined}
            info={info !== undefined ? info : undefined}
            toucherror={toucherror !== undefined ? toucherror : undefined}
            methods={methods}
            render={({
                         // @ts-ignore
                         value, onChange, onBlur, name, isDirty, isTouched, warning, info, toucherror
                     }) => {
                return (
                    <>
                        <div className={'container'} key={"container_textarea_" + name}>

                            <IDSTextarea block invalid={getIDSInputInvalidValue(name, methods)} novalidation>
                                <label>{label}{getHelpTextPopOverIfAny(helpTextCode, editDisabled)}</label>
                                <textarea className={'textarea100'}
                                          {...methods.register(name, {setValueAs: (value: string) => removeTabsInValue(value)})}
                                          {...{value, onChange, onBlur, name}} disabled={editDisabled}
                                          key={"textarea_" + name} maxLength={maxLength === undefined ? 256 : maxLength}
                                          style={{
                                              width: '100%', minWidth: "300px"
                                          }}/>
                                {(!!toucherror && isTouched && !editDisabled) &&
                                    <IDSErrorMessage>{toucherror}</IDSErrorMessage>
                                }
                            </IDSTextarea>
                            <p style={{lineHeight: "50%"}}>
                                <br/>
                                <RHookFormValidationMessage name={name} editDisabled={editDisabled}/>
                                {(!!warning && isTouched && !editDisabled) && <FormFieldWarnMessage text={warning}/>}
                                {!(!!warning && isTouched && !editDisabled) && (warn(methods.getValues(name), name) !== undefined &&
                                        warn(methods.getValues(name), name) !== false && !editDisabled) &&
                                    <FormFieldWarnMessage text={warn(methods.getValues(name), name)}/>}
                                {(!!info && isTouched && !editDisabled) && <FormFieldInfoMessage text={info}/>}
                            </p>
                            <br/>
                        </div>
                    </>
                );
            }}
        />
    );
};
export default RHookFormTextAreaController;