import React from "react";
import {tagSectorSystemUrl, tagTerminologyServiceBaseSystemUrl} from "../model/defaultvalues/Constant";
import {getCurrentUserInBrowserSession} from "../services/APIUtils";
import {ROLE_ADMIN, ROLE_USER} from "../constants/index";
import {isDraft, isRetired} from "../components/artifact/versioninfo/VersionUtil";
import {removeLeadingZeros} from "../services/localstorageconcepts/SectorService";

export const permissionToEdit = (methods) => {
    let permission = false;
    let currentUser = getCurrentUserInBrowserSession();
    if ((currentUser !== undefined && currentUser != null)) {
        if (currentUser.selectedRole === ROLE_ADMIN) {
            permission = adminPermissionToSeeEditButton(methods) && !isRetired(methods);
        } else if (currentUser.selectedRole === ROLE_USER) {
            permission = userPermissionToSeeEditButton(methods) && !isRetired(methods);
        }
    }
    return permission;
}

export const permissionToSeeSaveButton = () => {
    let currentUser = getCurrentUserInBrowserSession();
    let permission = false;
    if (currentUser !== undefined && currentUser != null) {
        permission = true;
    }
    return permission;
}

export const isLocked = (editDisabled, methods) => {
    if (editDisabled) {
        return true;
    }
    //if in edit mode and if not in draft
    let islocked = !editDisabled && !isDraft(methods);
    return islocked;
}

const adminPermissionToSeeEditButton = (methods) => {
    return hasCorrectTagSystemUrl(methods, tagTerminologyServiceBaseSystemUrl)
}

const hasCorrectTagSystemUrl = (methods, tagSystemUrl) => {
    let permission = false;
    let tags = methods.getValues("meta.tag");
    if (tags !== undefined) {
        for (let tagObj of tags) {
            if (tagObj.system === tagSystemUrl) {
                permission = true;
            }
        }
    }
    return permission;
}


export const userPermissionToSeeEditButton = (methods) => {
    let currentUser = getCurrentUserInBrowserSession();
    let permission = false;
    let code = undefined;
    let tags = methods.getValues("meta.tag");
    if (tags !== undefined) {
        for (let tagObj of tags) {
            if (tagObj.system.includes(tagSectorSystemUrl)) {
                code = tagObj.code;
            }
        }

        if (((currentUser !== undefined && currentUser != null) && (code !== undefined))) {
            const selectedSectorCode = removeLeadingZeros(currentUser.selectedSectorCode);
            const sectorCode = removeLeadingZeros(code);
            if (((sectorCode !== undefined && sectorCode !== null) &&
                    (selectedSectorCode !== undefined && selectedSectorCode !== null)) &&
                selectedSectorCode === sectorCode) {
                permission = true;
            }
        }
    }
    return permission;
}