import React from "react";
import {
    IValueSetComposeInclude,
    IValueSetComposeIncludeConcept
} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {
    compareTwoArrayOfObjects,
    isCodeSystemWithoutCodesInMetaDataObject,
    sortStrings
} from "../../../common/CommonFunctions";
import {ICodeSystem, IValueSet} from "fhir-typescript-models";


export const formatIncludeArrayToSaveIfAllCodesSelected = (includeArrayToSave: IValueSetComposeInclude[], codeSystemWithAllCodesArray: ICodeSystem[]) => {
    if (includeArrayToSave.length !== 0) {

        /*    sortIncludeArrayBySystemAndVersion(includeArrayToSave);
            sortCodeSytemArrayBySystemAndVersion(codeSystemWithAllCodesArray);*/

        for (let i = 0; i < includeArrayToSave.length; i++) {

            let includeToSave = includeArrayToSave[i];
            let codeSystemWithAllCodes = codeSystemWithAllCodesArray[i];

            // @ts-ignore
            if (codeSystemWithAllCodes !== undefined && !isCodeSystemWithoutCodesInMetaDataObject(codeSystemWithAllCodes.url)) {
                if (includeToSave.concept?.length === codeSystemWithAllCodes.concept?.length) {
                    //If all codes from codesystem are selected then they are not defined in concept except when they have a different display/synonym
                    //TODO Synonym handling
                    includeToSave.concept = undefined;
                }
            }
        }
    }
}

export const getIncludeArrayWithoutEmptyConceptArray = (includeArray: IValueSetComposeInclude[]): IValueSetComposeInclude[] => {
    let includesWithoutEmpty: IValueSetComposeInclude[] = [];
    if (includeArray !== undefined && includeArray.length != 0) {
        includesWithoutEmpty = includeArray.filter(item => !(item.concept === undefined || item.concept.length == 0));
    }
    return includesWithoutEmpty;
}

export function removeComposeFormExtraProperties(includeArray: IValueSetComposeInclude[]) {

    for (let i = 0; i < includeArray.length; i++) {
        // @ts-ignore
        includeArray[i].tabindex = undefined;
        includeArray[i].id = undefined;

        var array = [];
        if (includeArray[i].concept !== undefined) {
            // @ts-ignore
            for (let j = 0; j < includeArray[i].concept.length; j++) {
                // @ts-ignore
                var concept = includeArray[i].concept[j];
                array.push({code: concept.code, display: concept.display});
            }
        }
        includeArray[i].concept = array.length == 0 ? undefined : array;
    }
}

export const isIncludeArraysEqual = (includeArrayA: IValueSetComposeInclude[], includeArrayB: IValueSetComposeInclude[]): boolean => {

    if (isIncludeArraysEqualsOnArrayEmpty(includeArrayA, includeArrayB) == false) {
        return false;
    }
    if (includeArrayA.length != includeArrayB.length) {
        return false;
    } else {
        return isIncludeArrayEqualsWhenSorted(includeArrayA, includeArrayB);
    }
}

const isIncludeArrayEqualsWhenSorted = (includeArrayA: IValueSetComposeInclude[], includeArrayB: IValueSetComposeInclude[]): boolean => {

    sortIncludeArrayBySystemAndVersion(includeArrayA);
    sortIncludeArrayBySystemAndVersion(includeArrayB);

    if (isSortedIncludesConceptArrayEqualsOnArrayLength(includeArrayA, includeArrayB) == false) {
        return false;
    } else {
        return isSortedIncludesConceptArrayEquals(includeArrayA, includeArrayB);
    }
}

const isIncludeArraysEqualsOnArrayEmpty = (includeArrayA: IValueSetComposeInclude[], includeArrayB: IValueSetComposeInclude[]): boolean => {

    // @ts-ignore
    let isincludeArrayAConceptEmpty = includeArrayA.concept == undefined || includeArrayA.concept.length == 0;
    // @ts-ignore
    let isincludeArrayBConceptEmpty = includeArrayB.concept == undefined || includeArrayB.concept.length == 0;

    if (isincludeArrayAConceptEmpty || isincludeArrayBConceptEmpty) {
        return isincludeArrayAConceptEmpty == isincludeArrayBConceptEmpty;
    } else {
        return false;
    }
}


const isSortedIncludesConceptArrayEquals = (sortedIncludeArrayA: IValueSetComposeInclude[], sortedIncludeArrayB: IValueSetComposeInclude[]): boolean => {

    removeComposeFormExtraProperties(sortedIncludeArrayA);
    removeComposeFormExtraProperties(sortedIncludeArrayB);

    let isSortedIncludesConceptArrayEquals = true;
    for (let i = 0; i < sortedIncludeArrayA.length; i++) {
        if (!isSortedIncludesConceptArrayEquals) {
            break;
        } else {
            isSortedIncludesConceptArrayEquals = compareTwoArrayOfObjects(sortedIncludeArrayA[i].concept, sortedIncludeArrayB[i].concept)
        }
    }
    return isSortedIncludesConceptArrayEquals;
}

const isSortedIncludesConceptArrayEqualsOnArrayLength = (sortedIncludeArrayA: IValueSetComposeInclude[], sortedIncludeArrayB: IValueSetComposeInclude[]): boolean => {

    let isIncludeArraysEqualsOnArrayLength = true;
    if (sortedIncludeArrayA.length != sortedIncludeArrayB.length) {
        return isIncludeArraysEqualsOnArrayLength;
    } else {
        for (let i = 0; i < sortedIncludeArrayA.length; i++) {
            if (!isIncludeArraysEqualsOnArrayLength) {
                break;
            } else if (!isSortedIncludesConceptArrayEqualsOnArrayEmpty(i, sortedIncludeArrayA, sortedIncludeArrayB)) {
                isIncludeArraysEqualsOnArrayLength = false;
                break;
            } else {
                // @ts-ignore
                isIncludeArraysEqualsOnArrayLength = sortedIncludeArrayA[i].concept.length == sortedIncludeArrayB[i].concept.length;
            }
        }
        return isIncludeArraysEqualsOnArrayLength;
    }
}

const isSortedIncludesConceptArrayEqualsOnArrayEmpty = (i: number, sortedIncludeArrayA: IValueSetComposeInclude[], sortedIncludeArrayB: IValueSetComposeInclude[]): boolean => {

    let isIncludeArraysEqualsOnArrayEmptyLength = true;
    // @ts-ignore
    let isIncludeArrayAConceptEmpty = sortedIncludeArrayA[i].concept == undefined || sortedIncludeArrayA[i].concept.length == 0;
    // @ts-ignore
    let isIncludeArrayBConceptEmpty = sortedIncludeArrayB[i].concept == undefined || sortedIncludeArrayB[i].concept.length == 0;

    if (isIncludeArrayAConceptEmpty || isIncludeArrayBConceptEmpty) {
        isIncludeArraysEqualsOnArrayEmptyLength = (isIncludeArrayAConceptEmpty == isIncludeArrayBConceptEmpty);
    }
    return isIncludeArraysEqualsOnArrayEmptyLength;
}


const isIncludeConceptsSameLengtAsAllCodesInCodeSystem = (includeArray: IValueSetComposeInclude[], codeSystemWithAllCodesArray: ICodeSystem[]): boolean => {
    let sameLength = true;
    if (includeArray.length !== 0) {

        sortIncludeArrayBySystemAndVersion(includeArray);
        sortCodeSytemArrayBySystemAndVersion(codeSystemWithAllCodesArray);

        for (let i = 0; i < includeArray.length; i++) {

            let includeToSave = includeArray[i];
            let includeWithAllCodes = codeSystemWithAllCodesArray[i];

            if (includeToSave.concept?.length != includeWithAllCodes.concept?.length) {
                //If all codes from codesystem are selected then they are not defined in concept except when they have a different display/synonym
                //TODO Synonym handling
                sameLength = false;
                break;
            }
        }
    }
    return sameLength;
}

/*export const sortArraysAccordingToSystemAndVersion = (includeArraySaved: IValueSetComposeInclude[],
                                                   includeArrayInForm: IValueSetComposeInclude[],
                                                   codeSystemWithAllCodesArray: ICodeSystem[]): boolean => {
    sortIncludeArrayBySystemAndVersion(includeArraySaved);
    sortIncludeArrayBySystemAndVersion(includeArrayInForm);
    sortCodeSytemArrayBySystemAndVersion(codeSystemWithAllCodesArray);

}*/

export const isIncludeConceptsInFormSameAsSaved = (includeArraySaved: IValueSetComposeInclude[],
                                                   includeArrayInForm: IValueSetComposeInclude[],
                                                   codeSystemWithAllCodesArray: ICodeSystem[]): boolean => {
    sortIncludeArrayBySystemAndVersion(includeArraySaved);
    sortIncludeArrayBySystemAndVersion(includeArrayInForm);
    sortCodeSytemArrayBySystemAndVersion(codeSystemWithAllCodesArray);

    if (includeArraySaved.length != includeArrayInForm.length) {
        return false;
    }

    let isSameIncludeSavedAsInForm = true;
    for (let i = 0; i < includeArraySaved.length; i++) {
        if (isSameIncludeSavedAsInForm) {
            let includeSaved = includeArraySaved[i];
            let sameIncludeAsSavedInForm = undefined;
            for (let includeInForm of includeArrayInForm) {
                if ((includeSaved.system === includeInForm.system) && (includeSaved.version === includeInForm.version)) {
                    sameIncludeAsSavedInForm = includeInForm;
                }
            }


            let codeSystemWithAllCodes = codeSystemWithAllCodesArray[i];

            try {
                if (includeSaved.concept?.length != sameIncludeAsSavedInForm?.concept?.length) {
                    //If all codes from codesystem are selected then they are not defined in concept except when they have a different display/synonym
                    //TODO Synonym handling
                    isSameIncludeSavedAsInForm = false;
                }
            } catch (error) {
                console.error("Error when comparing saved includeArray with includeInForm: includeArray;"
                    + JSON.stringify(includeSaved) + "includeInForm :" +
                    JSON.stringify(sameIncludeAsSavedInForm) + ", error: " + error);
                isSameIncludeSavedAsInForm = false;
            }


            sortIncludeConceptArrayByCode(includeSaved.concept);
            sortIncludeConceptArrayByCode(sameIncludeAsSavedInForm?.concept);

            // @ts-ignore
            if (includeSaved.concept !== undefined && sameIncludeAsSavedInForm.concept !== undefined)
                for (let i = 0; i < includeSaved.concept?.length; i++) {
                    // @ts-ignore
                    const conceptAlreadySaved = includeSaved.concept[i];
                    // @ts-ignore
                    const conceptInForm = sameIncludeAsSavedInForm.concept[i];


                    if (conceptAlreadySaved.code !== conceptInForm.code) {

                        isSameIncludeSavedAsInForm = false;
                    }
                }
        } else {
            break;
        }

    }
    return isSameIncludeSavedAsInForm;
}


const sortIncludeArrayBySystemAndVersion = (includeArray: IValueSetComposeInclude[]) => {
    try {
        includeArray.sort((a, b) => {
            // @ts-ignore
            const systemA = a.system.toUpperCase();
            // @ts-ignore
            const systemB = b.system.toUpperCase();

            if (systemA === systemB) {
                // @ts-ignore
                const versionA = a.version.toUpperCase();
                // @ts-ignore
                const versionB = b.version.toUpperCase();

                return sortStrings(versionA, versionB);

            } else {
                return sortStrings(systemA, systemB);
            }
        });
    } catch (error) {
        console.error("Error when sorting includeArray: " + JSON.stringify(includeArray) + ", error: " + error);
    }
}

const sortIncludeConceptArrayByCode = (conceptArray: IValueSetComposeIncludeConcept[] | undefined) => {
    if (conceptArray !== undefined) {
        try {
            conceptArray.sort((a, b) => {
                // @ts-ignore
                const codeA = a.code.toUpperCase();
                // @ts-ignore
                const codeB = b.code.toUpperCase();

                return sortStrings(codeA, codeB);

            });
        } catch (error) {
            console.error("Error when sorting conceptArray: " + JSON.stringify(conceptArray) + ", error: " + error);
        }
    }
}

const sortCodeSytemArrayBySystemAndVersion = (codeSystemArray: ICodeSystem[]) => {
    try {
        codeSystemArray.sort((a, b) => {
            // @ts-ignore
            const systemA = a.url.toUpperCase();
            // @ts-ignore
            const systemB = b.url.toUpperCase();

            if (systemA === systemB) {
                // @ts-ignore
                const versionA = a.version.toUpperCase();
                // @ts-ignore
                const versionB = b.version.toUpperCase();

                return sortStrings(versionA, versionB);

            } else {
                return sortStrings(systemA, systemB);
            }
        });
    } catch (error) {
        console.error("Error when sorting codeSystemArray: " + JSON.stringify(codeSystemArray) + ", error: " + error);
    }
}

