import React, {useState} from "react";

import {codesystem, valueset, conceptmap} from "../model/defaultvalues/Constant";
import {
    IDSCol,
    IDSContainer,
    IDSRow
} from "@inera/ids-react";
import Search from "../components/artifact/search/Search";
import {displayLinebreak} from "../styles/TTStyles";
import ExpandableTextInHtml from "../components/common/rhookform/ExpandableTextInHtml";


const ArtifactSearch = (props) => {
    const artifactTypeChoice = [codesystem, valueset, conceptmap];
    const heading = "Sök artefakt";
    const [showFull, setShowFull] = useState(false);

    const text =
    " \nVälkommen till Terminologitjänsten. Har finns kodverk, urval ur kodverk och mappningar mellan kodverk " +
        "från regioner och Inera, samt relevanta kodverk från myndigheter som används inom vården i " +
        "Sverige. \n \nTjänsten är öppen att använda för alla utan att logga in. Material kan laddas " +
        "hem direkt från den här sidan. \n \n Regionernas användare som ska skapa och redigera innehåll loggar " +
        "in med SITHS-kort efter att tjänsten är beställd och uppsatt. \n \nAnsvar för att innehållet i ett " +
        "kodverk, urval eller en mappning är korrekt och håller önskvärd kvalitet ligger hos den funktion " +
        "som anges som “Innehållsansvarig” i kodverket. Inera ansvarar endast för tillgängliggörandet. "

    const getTextEnd = (test) => {
        return (test.length > 96) ? "..." : "";
    };

    return (
        <div>
            <div>
                <IDSContainer gutterless={true}>
                    <IDSRow>
                        <IDSCol>
                            <br/><br/>
                            <h2 className="ids-heading-m">{heading}</h2>
                        </IDSCol>
                    </IDSRow>
                    <IDSRow>
                        <IDSCol cols="8">
                            <div>
                                <p className="ids-preamble">
                                    <div style={displayLinebreak}>
                                        <ExpandableTextInHtml limit={316}
                                                        text={text}/>
                                        <br/>
                                    </div>
                                </p>
                            </div>
                        </IDSCol>
                        <IDSCol cols="4">
                        </IDSCol>
                    </IDSRow>
                </IDSContainer>
            </div>
            <Search artifactTypeChoice={artifactTypeChoice}/>
        </div>
    );

};

export default ArtifactSearch;
