import React from "react";
import {IDSIconInformation} from "@inera/ids-react";
import {FC} from "react";


let renderCount = 0;

interface IconInfoMessageProps {
    text?: any,
    slotValue?: string,
}

const IconInfoMessage: FC<IconInfoMessageProps> = ({
                                                       text,
                                                       slotValue
                                                   }: IconInfoMessageProps) => {
    renderCount++;

    return (
        <table>
            <tbody>
            <tr>
                <td style={{backgroundColor: "#f0f0f0"}}>
                    <IDSIconInformation
                        color={"#000000"} color2={"#000000"}
                        size={"s"} slot={slotValue}/>
                </td>
                {text !== undefined &&
                    <td>
                        {text}
                    </td>
                }
            </tr>
            </tbody>
        </table>
    );
}

export default IconInfoMessage;
