import React, {useEffect, useRef, useState, useCallback} from 'react';

import {useParams} from 'react-router-dom';
import {removeDefaultCodeSystemFormValues} from "../../model/defaultvalues/CodeSystemDefaultFormValue";
import {SubmitHandler, FormProvider, useForm, UseFormProps, UseFormReturn} from 'react-hook-form';
import {IDSCol, IDSContainer, IDSRow, IDSTab, IDSTabPanel, IDSTabs} from "@inera/ids-react";
import {handleMessageList} from "../../components/common/validation/MetaValidationRules";
import {ValidationHeader} from "../../components/common/page/header/ValidationHeader";
import {updateResponseErrorHandling} from "../ArtifactPageCommons";
import {externallyValidateCreatedInfoNotifications, externallyValidateMetaData} from "../../services/ValidationService";
import Concept from "../../components/artifact/concept/Concept";
import VersionInformation from "../../components/artifact/versioninfo/VersionInformation";
import MetaData from "../../components/artifact/meta/MetaData";
import Client from 'fhir-kit-client';
import History from "../../components/artifact/meta/readonly/History";
import {
    codesystem,
    valueset,
    fhirUrl,
    swedishICD10_name, swedishICD10_83_name, swedishICD10_miniexample
} from "../../model/defaultvalues/Constant";
import {retrieveVersionHistory} from "../../services/ArtifactCommonClient";
import {ArtifactHeader} from "../../components/common/page/header/ArtifactHeader";
import {nowDateYearMonthDay} from "../../components/common/CommonFunctions";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {ICodeSystem} from "fhir-typescript-models";
import {isLocked, permissionToEdit} from "../../user/PermissionUtil";
import {schema, schemaNewVersion} from "../../components/common/validation/ValidationInternalMetaDataYupErrorRules";
import {
    translateIdentifierFromUrn,
    translateIdentifierToUrn
} from "../../components/artifact/meta/IdentifierTranslator";
import {ACCESS_TOKEN} from "../../constants";
import {string} from "yup";
import {IRHookFormValidationMessage} from "../../components/common/rhookform/IRHookFormValidationMessage";
import {getRenderCountText} from "../../components/common/RenderCount";
import {
    contactListEmpty, emptyIndentifierFormValues,
    emptyIndentifierValues,
    identifierList
} from "../../model/defaultvalues/MetaDataDefaultFormValue";
import {conceptList} from "../../model/defaultvalues/CodeSystemDefaultFormValue";
import RelatedArtifacts from "../../components/artifact/meta/readonly/RelatedArtifacts";
import {
    handleIfValidationExternalNotificationsAllTypes
} from "../../components/common/validation/external/ValidationExternalNotificationService";

import CreateNewVersionDialog from "../../components/common/page/create/CreateNewVersionDialog";
import {getSectorTitleFromArtefact} from "../../services/localstorageconcepts/SectorService";
import ITabName from "../../components/common/page/ITabName";
import {collectNotices} from "../../components/common/page/TabName";
import {useLocation} from "react-router-dom";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {addTTExtensionsToForm, extensionList} from "../../model/defaultvalues/MetaDataExtensionDefaultFormValue";
import {validateDisplayAfterSubmit} from "../../components/artifact/concept/DisplayValidationCollected";
import {validateDefinitionAfterSubmit} from "../../components/artifact/concept/DefinitionValidation";
import {handleUpdatedStatus} from "../../components/artifact/versioninfo/StatusUtil";


type TParams = { id: string, version: string }
const fhirClient = new Client({baseUrl: fhirUrl});
let renderCount = 0;


function CodeSystemReadUpdate() {

    const [editDisabled, setEditDisabled] = useState(true);
    const [editModeActivated, setEditModeActivated] = useState(false);
    const [foundHistory, setFoundHistory] = useState([]);
    const [relatedArtifactsArray, setRelatedArtifactsArray] = useState<any[]>([]);
    const [isRequesting, setIsRequesting] = useState(false);
    const [initialVersion, setInitialVersion] = useState<string>();
    const [initialStatus, setInitialStatus] = useState<string>();
    const [activateSaveSuccessNotice, setActivateSaveSuccessNotice] = useState(false);
    const [activateSaveErrorNotice, setActivateSaveErrorNotice] = useState(false);
    const [isFromDraftToActiveSave, setIsFromDraftToActiveSave] = useState(false);
    const [isFromActiveToRetiredSave, setIsFromActiveToRetiredSave] = useState(false);


    const [errorMessageList, setErrorMessageList] = useState<IRHookFormValidationMessage[]>([]);
    const [warnMessageList, setWarnMessageList] = useState<IRHookFormValidationMessage[]>([]);
    const [infoMessageList, setInfoMessageList] = useState<IRHookFormValidationMessage[]>([]);


    const createNewVersionButtonRef = useRef("create-new-version-button");
    const [showCreateNewVersionDialog, setShowCreateNewVersionDialog] = useState(false);

    const [metaDataNotices, setMetaDataNotices] = useState(0);
    const [contentDataNotices, setContentDataNotices] = useState(0);
    const [publishDataNotices, setPublishDataNotices] = useState(0);


    const handleErrorMessageList = (message: string, label: string, tabName: ITabName, index?: string, path?: string) => {
        handleMessageList(message, label, errorMessageList, setErrorMessageList, tabName, index, path);
    };


    const handleWarnMessageList = (message: string, label: string, tabName: ITabName, index?: string, path?: string) => {
        handleMessageList(message, label, warnMessageList, setWarnMessageList, tabName, index, path);
    };

    const handleInfoMessageList = (message: string, label: string, tabName: ITabName) => {
        handleMessageList(message, label, infoMessageList, setInfoMessageList, tabName);
    };


    const params = useParams();
    const search = useLocation().search;
    const editValue = new URLSearchParams(search).get('edit');
    const createdValue = new URLSearchParams(search).get('created');


    const methods: UseFormReturn<ICodeSystem, UseFormProps> = useForm<ICodeSystem>({
        defaultValues: {
            identifier: identifierList,
            extension: extensionList,
            concept: conceptList,
            contact: contactListEmpty,
            resourceType: codesystem,
            copyright: "",
            caseSensitive: false,
            hierarchyMeaning: "is-a",
            compositional: false,
            versionNeeded: false,
            content: "",
            version: "",
            status: ""
        },

        resolver: yupResolver(schema),
        reValidateMode: "onChange",
        shouldFocusError: false,
        mode: "onChange"

    });

    const fetchICD10 = (response: any) => {

        var icdCodeSystem = {
            id: response.id,
            name: response.name,
            title: response.title,
            version: response.version,
            description: response.description,
            meta: response.meta,
            publisher: response.publisher,
            date: response.lastUpdated,
            url: response.url,
            status: response.status,
            sektor: getSectorTitleFromArtefact(response),
            identifierare: JSON.stringify(response.identifier)
        }
        translateIdentifierFromUrn(icdCodeSystem);
        methods.reset(icdCodeSystem); // asynchronously reset your form values
        setInitialVersion(icdCodeSystem.version);
        retrieveVersionHistory(icdCodeSystem, codesystem, setFoundHistory);
        retrieveRelatedArtifacts(icdCodeSystem.url);
        return icdCodeSystem;
    };


    const resetAsyncForm = useCallback(async () => {

        fhirClient.read({resourceType: codesystem, id: params.id as string})
            // @ts-ignore
            .then(response => {

                if ((response.name === swedishICD10_name) ||
                    (response.name === swedishICD10_83_name) ||
                    (response.name === swedishICD10_miniexample)
                ) {
                    fetchICD10(response);
                    return null;
                } else {
                    addTTExtensionsToForm(response);
                    translateIdentifierFromUrn(response, editDisabled);
                    methods.reset(response); // asynchronously reset your form values
                    setInitialStatus(response.status);
                    return response;
                }
                // @ts-ignore
            }).then(response => {

            if (response != null) {
                setInitialVersion(response.version);

                retrieveVersionHistory(response, codesystem, setFoundHistory);
                if (permissionToEdit(methods) == true && editValue === "true" && response.status == "draft") {
                    setEditDisabled(false);
                    setEditModeActivated(true);
                }

                if (permissionToEdit(methods) == true && createdValue === "true" && response.status == "draft") {
                    externallyValidateCreatedInfoNotifications(methods.getValues(), infoMessageList,
                        handleInfoMessageList, methods, false, codesystem);
                }
            }

            return response;
        }).then(response => {
            if (response != null) {
                retrieveRelatedArtifacts(response.url);
                return response;
            }
        })
            .catch(e => {
                // @ts-ignore
                updateResponseErrorHandling(e, setIsRequesting, methods);
            });

    }, [methods.reset]);

    const validateArtifact = (editDisableStatus: boolean) => {
        externallyValidateMetaData(methods.getValues(), infoMessageList, handleInfoMessageList,
            warnMessageList, handleWarnMessageList,
            errorMessageList, handleErrorMessageList, methods, setIsRequesting, editDisableStatus, codesystem);
    }

    const retrieveRelatedArtifacts = (url: string) => {
        //TODO use when Ontoserver is upgraded, newer Ontoserver will handle reference
        /*        if (url) {
                    let params: any = {};
                    params["reference"] = url;
                    fhirClient
                        .search({resourceType: valueset, searchParams: params})
                        .then((data) => {
                            if (data.total != 0) {
                                const array = relatedArtifactsArray.concat(data.entry);
                                setRelatedArtifactsArray(array);
                            }
                        });
                }*/
    };


    useEffect(() => {
        resetAsyncForm()
    }, [resetAsyncForm])


    useEffect(() => {
        collectNotices(infoMessageList, warnMessageList, errorMessageList, setMetaDataNotices, setContentDataNotices, setPublishDataNotices);
    }, [errorMessageList, warnMessageList, infoMessageList])


    const getWarnTextExplanation = (total: number) => {
        let totalnumbersText: string = total == 1 ? (total.toString() + " varning") : (total.toString() + " varningar")
        let explanation: string = " - Behöver åtgärdas innan du kan aktivera artefakten";
        return <strong>{totalnumbersText}</strong>;
    };

    const getWarnText = (total: number) => {
        return total == 1 ? (total + " varning") : (total + " varningar")
    };

    const handleEditDisabled = (editDisableStatus: boolean) => {

        setEditDisabled(editDisableStatus);
        if (editDisableStatus == false) {
            validateArtifact(editDisableStatus);
            // @ts-ignore
            if (JSON.stringify(methods.getValues("identifier")) === JSON.stringify([emptyIndentifierValues])) {
                // @ts-ignore
                methods.setValue("identifier", [emptyIndentifierFormValues])
            }

        }
    };


    const submitCodeSystemUpdate: SubmitHandler<ICodeSystem> = async (data: ICodeSystem) => {

        // @ts-ignore
        if (data !== undefined && errorMessageList.length == 0) {
            setIsRequesting(true);
            const conceptsIncludingWarnInfo = data.concept;
            data = removeDefaultCodeSystemFormValues(data);
            data.date = nowDateYearMonthDay();
            translateIdentifierToUrn(data);

            await fhirClient.update({
                resourceType: codesystem,
                id: params.id,
                // @ts-ignore
                body: data,
                options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}}
            }).then(response => {
                //     methods.reset(response);
                if (response !== undefined) {

                    //   retrieveVersionHistoryOnSystemName(methods.getValues("name"), codesystem, setFoundHistory);
                    setIsRequesting(false);
                    translateIdentifierFromUrn(data, editDisabled);
                    addTTExtensionsToForm(data);
                    data.concept = conceptsIncludingWarnInfo;
                    methods.reset(data);
                    setInitialVersion(methods.getValues("version"));

                    // @ts-ignore
                    validateDisplayAfterSubmit(data.concept, warnMessageList, handleWarnMessageList, infoMessageList, handleInfoMessageList, methods);
                    // @ts-ignore
                    validateDefinitionAfterSubmit(data.concept, infoMessageList, handleInfoMessageList, methods);

                    handleIfValidationExternalNotificationsAllTypes(
                        // @ts-ignore
                        response, methods,
                        infoMessageList, handleInfoMessageList,
                        warnMessageList, handleWarnMessageList,
                        errorMessageList, handleErrorMessageList
                    )
                    let after = true;
                }

                const updatedStatus: boolean = handleUpdatedStatus(data, initialStatus, setInitialStatus, setIsFromDraftToActiveSave, setIsFromActiveToRetiredSave);

                retrieveVersionHistory(data, codesystem, setFoundHistory);

                setActivateSaveSuccessNotice(true);

            })
                .catch(e => {
                    setIsRequesting(false);
                    translateIdentifierFromUrn(data, editDisabled);
                    data.concept = conceptsIncludingWarnInfo;
                    if (initialStatus !== data.status) {
                        // @ts-ignore
                        methods.setValue("status", initialStatus);
                    }

                    // @ts-ignore
                    validateDisplayAfterSubmit(data.concept, warnMessageList, handleWarnMessageList, infoMessageList, handleInfoMessageList, methods);
                    // @ts-ignore
                    validateDefinitionAfterSubmit(data.concept, infoMessageList, handleInfoMessageList, methods);

                    console.error("FhirClient Update Error Response" + JSON.stringify(e));
                    if (e.response !== undefined && e.response.status == 400 && e.response.data !== undefined) {
                        handleIfValidationExternalNotificationsAllTypes(
                            e.response.data, methods,
                            infoMessageList, handleInfoMessageList,
                            warnMessageList, handleWarnMessageList,
                            errorMessageList, handleErrorMessageList
                        )
                        setActivateSaveErrorNotice(true);
                    } else {
                        setActivateSaveErrorNotice(true);
                    }
                });
        }
    };

    const createNewVersion = () => {
        setShowCreateNewVersionDialog(true);
        setEditDisabled(true);
        // @ts-ignore
        createNewVersionButtonRef.current.click();
    };

    const validateCodes = () => {
        // @ts-ignore
        const concepts: any[] = methods.getValues("concept");
        let hasNoConcept: boolean = false;
        // @ts-ignore
        if (concepts === undefined || (Array.isArray(concepts) == false) || concepts.length == 0) {
            hasNoConcept = true;
        } else if (concepts.length == 1) {
            const concept: ICodeSystemConcept = concepts[0];
            if (concept.code?.trim() === "") {
                hasNoConcept = true;
            }
        }
        return hasNoConcept;
    };

    renderCount++;

    return (
        <div>

            <CreateNewVersionDialog createButtonRef={createNewVersionButtonRef} artifactType={codesystem}
                                    showCreateDialog={showCreateNewVersionDialog}
                                    setShowCreateDialog={setShowCreateNewVersionDialog}
                                    data={methods.getValues()}
                                    setFoundHistory={setFoundHistory}/>
            <FormProvider {...methods}>
                <form id="codesystem-readupdate-form" key="codesystem-readupdate-form"
                      onSubmit={methods.handleSubmit(submitCodeSystemUpdate)}>
                    <ArtifactHeader editDisabled={editDisabled} setEditDisabled={handleEditDisabled}
                                    artifactType={codesystem} isRequesting={isRequesting}
                                    updateDisabled={editDisabled || errorMessageList.length !== 0}
                                    createNewVersion={createNewVersion}
                                    editModeActivated={editModeActivated}
                                    setEditModeActivated={setEditModeActivated}
                                    versionArray={foundHistory}
                    />
                    <ValidationHeader editDisabled={editDisabled}
                                      infoMessageList={infoMessageList}
                                      warnMessageList={warnMessageList}
                                      errorMessageList={errorMessageList}
                                      activateSaveSuccessNotice={activateSaveSuccessNotice}
                                      setActivateSaveSuccessNotice={setActivateSaveSuccessNotice}
                                      activateSaveErrorNotice={activateSaveErrorNotice}
                                      setActivateSaveErrorNotice={setActivateSaveErrorNotice}
                                      artefactType={codesystem}
                                      isFromDraftToActiveSave={isFromDraftToActiveSave}
                                      setIsFromDraftToActiveSave={setIsFromDraftToActiveSave}
                                      isFromActiveToRetiredSave={isFromActiveToRetiredSave}
                                      setIsFromActiveToRetiredSave={setIsFromActiveToRetiredSave}
                    />
                    <IDSContainer gutterless={true}>
                        <IDSRow justify="space-between">
                            <IDSCol>
                                <IDSTabs>
                                    <IDSTab label="Metadata"
                                            notifications={editDisabled ? 0 : metaDataNotices}></IDSTab>
                                    <IDSTab label="Innehåll"
                                            notifications={editDisabled ? 0 : contentDataNotices}></IDSTab>
                                    <IDSTab label="Versionsinformation"
                                            notifications={editDisabled ? 0 : publishDataNotices}></IDSTab>
                                    <IDSTab label="Versionshistorik"></IDSTab>
                                    <IDSTab label="Kopplade artefakter"></IDSTab>

                                    <IDSTabPanel>
                                        <MetaData artifactType={codesystem}
                                                  isLocked={isLocked}
                                                  editDisabled={editDisabled}
                                                  errorMessageList={errorMessageList}
                                                  handleInfoMessageList={handleInfoMessageList}
                                                  handleWarnMessageList={handleWarnMessageList}
                                                  handleErrorMessageList={handleErrorMessageList}
                                                  setWarnMessageList={setWarnMessageList}
                                                  setErrorMessageList={setErrorMessageList}
                                        />
                                    </IDSTabPanel>

                                    <IDSTabPanel>

                                        <Concept editDisabled={editDisabled}
                                                 handleErrorMessageList={handleErrorMessageList}
                                                 errorMessageList={errorMessageList}
                                                 setErrorMessageList={setErrorMessageList}
                                                 handleWarnMessageList={handleWarnMessageList}
                                                 warnMessageList={warnMessageList}
                                                 setWarnMessageList={setWarnMessageList}
                                                 handleInfoMessageList={handleInfoMessageList}
                                                 infoMessageList={infoMessageList}
                                                 setInfoMessageList={setInfoMessageList}/>

                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <VersionInformation artifactType={codesystem}
                                                            isLocked={isLocked(editDisabled, methods)}
                                                            editDisabled={editDisabled}
                                                            errorMessageList={errorMessageList}
                                                            handleErrorMessageList={handleErrorMessageList}
                                                            warnMessageList={warnMessageList}
                                                            handleWarnMessageList={handleWarnMessageList}
                                                            hasError={errorMessageList.length !== 0}
                                                            hasWarning={warnMessageList.length !== 0}
                                                            submitArtefact={submitCodeSystemUpdate}
                                                            validateCodes={validateCodes}/>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <History history={foundHistory}
                                            // @ts-ignore
                                                 id={params.id}
                                                 type={codesystem}
                                            // @ts-ignore
                                                 version={initialVersion}/>
                                    </IDSTabPanel>
                                    <IDSTabPanel>
                                        <RelatedArtifacts artifactArray={relatedArtifactsArray}
                                                          type={valueset}
                                                          title={"Urval som innehåller " + methods.getValues("title") + ":"}/>
                                    </IDSTabPanel>
                                </IDSTabs>

                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                </form>
                {/*    <DevTool control={methods.control}/>  set up the dev tool */}
            </FormProvider>
        </div>
    );
};

export default CodeSystemReadUpdate;
