import React, {useState} from 'react'
import * as XLSX from 'xlsx'
import {IDSAlert, IDSButton, IDSButtonGroup, IDSLink} from "@inera/ids-react";
import {importExcelForCodeSystemForm} from "../../model/defaultvalues/Constant";
import {
    getParameterCaseInsensitive,
    removeEmojiFromJson
} from "../../components/common/CommonFunctions";
import ConceptTableForImportCodeSystem from "../../components/artifact/concept/table/ConceptTableForImportCodeSystem";
import ImportConceptTable from "./ImportConceptTable";
import {checkHasCode} from "./ImportCommonFunctions";

//TODO Remove ValueSet related import code as there is a new ImportExcelForValueSet.tsx
// that specific for CodeSystem for Valuset import
function ImportExcelForCodeSystem(props) {

    // on change states
    const [excelFile, setExcelFile] = useState(null);
    const [excelFileError, setExcelFileError] = useState(null);
    const formRef = React.useRef();
    // submit
    const [excelData, setExcelData] = useState(null);
    // it will contain array of objects

    const [codeDuplicates, setCodeDuplicates] = useState(null);
    const [displayDuplicates, setDisplayDuplicates] = useState(null);
    const [fetchedCodes, setFetchedCodes] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);

    // handle File
    const fileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

    // Ref object to reference the input element
    const inputFile = React.useRef(null);

    const importConcepts = () => {
        props.replaceCodes(excelData);
        setExcelData(null);
    }

    const displayConfirmationDialog = async () => {
        await cleanDocumentElement();
        setConfirmationDialog(true)
    }

    const cleanDocumentElement = async () => {
        document.getElementById(importExcelForCodeSystemForm).reset();
        formRef.current.reset();
    }


    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            // console.log(selectedFile.type);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                //console.log("IMPORT handleFile selectedFile has type")
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFileError(null);
                    setExcelFile(e.target.result);
                }
            } else {
                setExcelFileError('Vänligen välj bara Excel-fil typer');
                setExcelFile(null);
            }
        } else {
            console.log("IMPORT handleFile NO selectedFile")
            console.log('plz select your file');
        }
    }

    const fetch = () => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, {type: 'buffer'});
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            let data = XLSX.utils.sheet_to_json(worksheet);

            if (checkHasCode(worksheet)) {

                data = data.map((e) => {
                    return {
                        code: getParameterCaseInsensitive(e, "kod"),
                        display: getParameterCaseInsensitive(e, "klartext"),
                        definition: getParameterCaseInsensitive(e, "beskrivning")
                    }
                });

                data = removeEmojiFromJson(data);

                setExcelData(data);
                const notUniqueCode = data.filter(
                    (obj, index) =>
                        data.findIndex((item) => ((
                                    (item.code !== undefined) && (obj.code !== undefined) &&
                                    (item.code.toUpperCase() === obj.code.toUpperCase()))
                            )
                        ) !== index
                );

                notUniqueCode.length == 0 ? setCodeDuplicates(null) : setCodeDuplicates(notUniqueCode);

                setFetchedCodes(true);
            } else {
                setExcelData(null);
            }
        } else {
            setExcelData(null);
        }
        formRef.current.reset();
    }

    const jumpToReleventDiv = (id) => {
        const releventDiv = document.getElementById(id);
        // behavior: "smooth" parameter for smooth movement
        releventDiv.scrollIntoView({behavior: "smooth"});
    }

    const removeRow = (index) => {
        if (index > -1) { // only splice array when item is found
            excelData.splice(index, 1);
            var newArray = [...excelData]; // make a separate copy of the array
            setExcelData(newArray)
        }
    }

    const getButtons = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <IDSButtonGroup>
                    <IDSButton type="button" secondary size="m">
                        <button type="button" onClick={() => props.closeDialog()}>
                            AVBRYT
                        </button>
                    </IDSButton>
                    <IDSButton type="button" secondary toggle active size="m">
                        <button type="button"
                                onClick={() => confirmationDialog ? importConcepts() : displayConfirmationDialog()}>
                            LÄS IN KODPOSTER
                        </button>
                    </IDSButton>
                </IDSButtonGroup>
            </div>
        );
    };

    return (
        <div>
            {
                confirmationDialog ?
                    <div>
                        Du kommer nu att rensa alla kodposter i befintligt innehåll och ersätta allt med kodposter från
                        filen. Vill du fortsätta?
                        <br></br>
                        <br></br>
                        {getButtons()}
                    </div>
                    :
                    <div className="container">

                        {/* upload file section */}
                        <div>
                            <form autoComplete="off" id={importExcelForCodeSystemForm} ref={formRef}>
                                När du importerar koder från fil rensar och ersätter de alla befintliga kodposter.
                                <br></br>
                                <br></br>
                                Välj en excel-fil med kodposter att importera
                                <br></br>
                                <br></br>
                                <input type='file' className='form-control' onChange={handleFile} required
                                       ref={inputFile}></input>
                                {excelFileError && <div className='text-danger'
                                                        style={{marginTop: 5 + 'px'}}>{excelFileError}</div>}
                                <br></br>
                                <br></br>
                                <IDSButton type="button" secondary size="s">
                                    <button type="button" onClick={() => fetch()}>
                                        Hämta koder från fil
                                    </button>
                                </IDSButton>
                            </form>
                        </div>

                        <br></br>
                        <hr></hr>

                        {(fetchedCodes) &&
                            <div>
                                {(codeDuplicates == null || codeDuplicates.length == 0) &&
                                    <h4 className="ids-heading-xs">Inspektera hämtade koder</h4>}
                                <div className='viewer'>
                                    {excelData !== null && (
                                        <div className='table-responsive'>
                                            <div>
                                                {(codeDuplicates !== null && codeDuplicates.length !== 0) &&
                                                    <div>


                                                        <div>
                                                            <h4 className="ids-heading-xs">Det finns dubbletter i
                                                                den
                                                                valda filen</h4>
                                                            För att kunna importera kodposterna behöver dubbletter i
                                                            kod tas bort i den aktuella
                                                            filen.
                                                            <br></br>
                                                            <br></br>
                                                            <ImportConceptTable data={codeDuplicates}
                                                                                isErrorTable={true}/>
                                                            <IDSAlert
                                                                sricontitle={"Kodposterna går inte att läsa in"}
                                                                type={"error"}>
                                                                Ta bort dubbletter i den fil du vill läsa in för att
                                                                kunna gå vidare.
                                                            </IDSAlert>
                                                            <br></br>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'left',
                                                                alignItems: 'left'
                                                            }}>
                                                                <IDSButtonGroup>
                                                                    <IDSButton type="button" secondary size="m">
                                                                        <button type="button"
                                                                                onClick={() => props.closeDialog()}>
                                                                            AVBRYT
                                                                        </button>
                                                                    </IDSButton>
                                                                </IDSButtonGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {excelData !== null && excelData.length != 0 &&
                                                    (codeDuplicates === null || codeDuplicates.length === 0) &&
                                                    getButtons()
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
            }
        </div>
    );
}


export default ImportExcelForCodeSystem;