import React from "react";
import {IDSIconAttention} from "@inera/ids-react";
import {FC} from "react";


let renderCount = 0;

interface IconWarningMessageProps {
    text?: any,
    slotValue?: string,
}

const IconWarningMessage: FC<IconWarningMessageProps> = ({
                                                                         text,
                                                                         slotValue
                                                                     }: IconWarningMessageProps) => {
    renderCount++;

    return (
        <table>
            <tbody>
            <tr>
                <td style={{backgroundColor: "#fef7dc"}}>
                    <IDSIconAttention
                        color={"#000000"} color2={"#000000"}
                        size={"s"}  slot={slotValue}/>
                </td>
                {text !== undefined &&
                    <td>
                        {text}
                    </td>
                }
            </tr>
            </tbody>
        </table>
    );
}

export default IconWarningMessage;
