import React, {FC} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import VersionStatusForm from "../../../artifact/versioninfo/VersionStatusForm";
import {useFormContext} from "react-hook-form";
import TTBaseDialog from "./TTBaseDialog";


interface VersionStatusDialogProps {
    requestedStatus: string,
    closeDialog: any,
    showDialog: any,
    dialogRef: any,
    setShowDialog: any
}

export const VersionStatusDialog: FC<VersionStatusDialogProps> = ({
                                                                      requestedStatus,
                                                                      closeDialog,
                                                                      showDialog,
                                                                      dialogRef,
                                                                      setShowDialog
                                                                  }) => {

    const methods = useFormContext();


    const getHeadline = () => {
        const begin = "Du håller på att "
        if (requestedStatus === "active") {
            return begin + "aktivera: " + methods.getValues("title");
        } else if (requestedStatus === "retired") {
            return begin + "avställa: " + methods.getValues("title");
        }
    }

    return (
        <div>
            <TTBaseDialog dialogRef={dialogRef}
                          setShowDialog={setShowDialog}
                          showDialog={showDialog}
                          content={<VersionStatusForm closeDialog={closeDialog}
                                                      requestedStatus={requestedStatus}/>}
                          headingText={getHeadline()}/>
        </div>
    );
};
export default VersionStatusDialog;