import React, {FC} from 'react';
import {IDSIconAttention} from "@inera/ids-react";
import TableCellTooltip from "./TableCellTooltip";
import IconWarningMessage from "../../validation/message/IconWarningMessage";

let renderCount = 0;

interface TableCellWarningTooltipProps {
    text: string
}

export const TableCellWarningTooltip: FC<TableCellWarningTooltipProps> = ({
                                                                              text
                                                                          }) => {
    renderCount++;

    return (<TableCellTooltip
            text={text}
            title={"Varning"}
            idsIcon={
                <IconWarningMessage slotValue={"trigger"}/>
            }
        />
    )
        ;
};

export default TableCellWarningTooltip;