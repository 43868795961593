import React, {FC, useState} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import {IDSButton, IDSDialog, IDSLink} from "@inera/ids-react";
import CreateDialogForm from "../create/CreateDialogForm";
import {getArtifactSwedishLabel} from "../../CommonFunctions";
import TTBaseDialog from "../dialog/TTBaseDialog";
import {ROLE_USER, SYSTEM_ADMIN} from "../../../../constants";

interface SectorDialogContentProps {
    currentUser: any,
    selectSector: any
}

export const SectorDialogContent: FC<SectorDialogContentProps> = ({
                                                                      currentUser,
                                                                      selectSector
                                                                  }) => {


    return (
        <div>
            {
                // @ts-ignore
                currentUser?.roles?.map((role) => (
                    role.roleType === ROLE_USER
                        ? (<div key={role.roleType}>
                            {
                                // @ts-ignore
                                role?.authorities.map((authority, index) => {
                                    return <div key={authority.sector.title}>
                                        <IDSButton type="button" secondary>
                                            <button type="button" onClick={() => {
                                                selectSector(role.roleType, authority);
                                            }}>
                                                {authority.sector.title}
                                            </button>
                                        </IDSButton>
                                        <br/> <br/>
                                    </div>
                                })}
                        </div>)

                        :

                        (<div key={role.roleType}>
                            {
                                // @ts-ignore
                                role?.authorities.map((authority, index) => {
                                    return <div key={authority.sector.title}>
                                        <IDSButton type="button" secondary>
                                            <button type="button" onClick={() => {
                                                selectSector(role.roleType, authority);
                                            }}>
                                                {SYSTEM_ADMIN + authority.sector.title}
                                            </button>
                                        </IDSButton>
                                        <br/> <br/>
                                    </div>
                                })}
                        </div>)
                ))
            }
        </div>
    );
};
export default SectorDialogContent;