import React, {FC} from "react";

interface SearchTotalMessageProps {
    searchPerformed: boolean,
    total: number,
    lastSearchPhrase: string
}


export const SearchTotalMessage: FC<SearchTotalMessageProps> = ({
                                                                    searchPerformed,
                                                                    total,
                                                                    lastSearchPhrase,
                                                                }) => {

    let hitsText = total > 1 ? "träffar" : "träff";

    return (
        <div>

            {searchPerformed &&
                <div>
                    Din sökning på "{lastSearchPhrase}" gav {total} {hitsText}
                </div>
            }
        </div>
    );
};



