import React, {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {IDSErrorMessage} from "@inera/ids-react";
import {getErrorMessage, getErrorType} from "../rhookform/RHookFormMessageUtil";
import {YUPErrorLevelValidation} from "./ValidationInternalMetaDataYupErrorRules";
import {
    ERROR_EXTERNAL_NOTE_LEVEL,
    INFO_EXTERNAL_NOTE_LEVEL,
    WARNING_EXTERNAL_NOTE_LEVEL
} from "./external/ValidationExternalNotificationService";
import {hasValidationInternalMetaDataYupErrorRules} from "./ValidationInternalMetaDataYupErrorRulesUtil";
import FormFieldWarnMessage from "./message/FormFieldWarnMessage";
import FormFieldInfoMessage from "./message/FormFieldInfoMessage";

interface RHookFormMessageProps {
    name: string;
    editDisabled: boolean;
}

const RHookFormValidationMessage: FC<RHookFormMessageProps> = ({name, editDisabled}: RHookFormMessageProps) => {

    const methods = useFormContext();

    return (
        <div>
            {!editDisabled &&
                <div>
                    {(() => {
                        const errorType = getErrorType(name, methods);
                        const errorMessage = getErrorMessage(name, methods);

                        if (errorMessage === undefined) {
                            return (null)
                        } else if (hasValidationInternalMetaDataYupErrorRules(errorType) || errorType === ERROR_EXTERNAL_NOTE_LEVEL) {
                            return (
                                <IDSErrorMessage>{errorMessage}</IDSErrorMessage>
                            )
                        } else if (errorType === WARNING_EXTERNAL_NOTE_LEVEL) {
                            return (
                                // @ts-ignore
                                /*       <IDSErrorMessage><span style={statusDraftStyle}>{errorMessage}</span></IDSErrorMessage>*/
                                <FormFieldWarnMessage text={errorMessage}/>
                            )
                        } else if (errorType === INFO_EXTERNAL_NOTE_LEVEL) {
                            return (
                                <FormFieldInfoMessage text={errorMessage}/>
                            )
                        } else if (errorType.startsWith(YUPErrorLevelValidation)) {
                            //YUP test methods with named errorType
                            return (
                                // @ts-ignore
                                <IDSErrorMessage>{errorMessage}</IDSErrorMessage>
                            )
                        }
                    })()}
                </div>
            }
        </div>
    );
};


export default RHookFormValidationMessage;