import React, {useMemo,} from 'react';
import {MaterialReactTable,
    MaterialReactTableProps,
    MRT_ColumnDef,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import '@inera/ids-core/components/form/checkbox/register';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle, muiTablePropsFourColumnStyle,
} from "../../../../styles/TTStyles";
import ImportExport from "@mui/icons-material/ImportExport";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {IConceptMapGroupElement} from "fhir-typescript-models/dist/src/models/fhir/interfaces/IConceptMapGroupElement";
import {getEquivalenceSwedishText} from "../common/ConceptMapUtil";
import {filterCategoryOptions} from "../create/table/CMTableSourceUtil";
import {notmatched} from "../../../../model/defaultvalues/Constant";

let renderCount = 0;

const CMTableSourceReadOnly = (props: any) => {


    const columns = useMemo<MRT_ColumnDef<IConceptMapGroupElement>[]>(
        //column definitions...
        () => [
            {
                accessorKey: 'code',
                enableEditing: false,
                header: 'Källa',
                enableColumnFilters: true,
            },
            {
                // @ts-ignore
                accessorFn: (originalRow) => {
                    // @ts-ignore
                    return (originalRow.target === undefined || originalRow.target.length === 0) ? undefined : originalRow.target[0].code;
                },
                enableEditing: false,
                header: 'Mål',
                enableColumnFilters: true,
            },
            {
                // @ts-ignore
                accessorFn: (originalRow) => {
                    // @ts-ignore
                    return (originalRow.target === undefined || originalRow.target.length === 0) ? notmatched : originalRow.target[0].equivalence;
                },
                enableEditing: false,
                equivalence: 'equivalence',
                enableColumnFilters: true,
                filterVariant: 'multi-select',
                filterSelectOptions: filterCategoryOptions,
                header: 'Noggrannhet',
                Cell: ({ cell }) => {
                    // @ts-ignore
                    return <div>{getEquivalenceSwedishText(cell.getValue())}</div>;
                },
            },

            {
                // @ts-ignore
                accessorFn: (originalRow) => {
                    // @ts-ignore
                    return (originalRow.target === undefined || originalRow.target.length === 0) ? undefined : originalRow.target[0].comment;
                },
                enableEditing: false,
                enableColumnFilters: false,
                header: 'Kommentar',
            },

        ],
        [],
    );

    const commonTableProps: Partial<MaterialReactTableProps<IConceptMapGroupElement>> & {
        columns: MRT_ColumnDef<IConceptMapGroupElement>[];
    } = {
        columns,
    };


    return (
        <div>

            <MaterialReactTable
                enableTopToolbar={false}
                enableHiding={false}
                autoResetPageIndex={false}

                table={props.sourceTableInstanceRef}

                columns={columns}
                data={props.sourceTableData}
                enableSorting={true}
                enableRowSelection={false}
                localization={MRT_Localization_SV}

                displayColumnDefOptions={{
                    'mrt-row-drag': {
                        header: '',
                        size: 10,
                    },
                }}

                icons={{
                    MoreVertIcon: () => <ImportExport/>,
                    MoreHorizIcon: () => <MoreVertIcon/>,
                }}

                muiTableHeadRowProps={{
                    sx: muiTableHeadRowPropsStyle
                }}

                muiTableProps={{
                    sx: muiTablePropsFourColumnStyle
                }}

                muiTableBodyProps={{
                    sx: muiTableBodyPropsStyle
                }}

            />
            <br/><br/><br/>
        </div>

    );
};

export default CMTableSourceReadOnly;
