import React, {useMemo,} from 'react';
import {
    MaterialReactTable,
    MaterialReactTableProps,
    MRT_ColumnDef, MRT_Localization,
    MRT_Row,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import '@inera/ids-core/components/form/checkbox/register';
import {ConceptMapTableRowObj} from "../../model/ConceptMapTableRowObj";
import {
    muiSelectCheckboxPropsStyle,
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsSixColumnStyle
} from "../../../../../styles/TTStyles";
import ImportExport from "@mui/icons-material/ImportExport";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {categoryOptions, filterCategoryOptions} from "./CMTableSourceUtil";
import {IConceptMapGroupElement} from "fhir-typescript-models/dist/src/models/fhir/interfaces/IConceptMapGroupElement";
import {RHookSelectForTable} from "../../../../common/rhookform/RHookSelectForTable";
import RHookFormTextFieldForTable from "../../../../common/rhookform/RHookFormTextFieldForTable";

let renderCount = 0;

const CMTableSource = (props: any) => {

    renderCount++;

    const getLocalizationSVtt = () => {
        let mrtlocalizationSV: MRT_Localization = MRT_Localization_SV;
        // @ts-ignore
        mrtlocalizationSV.noRecordsToDisplay =
            <h5 style={{textAlign: 'center'}}>Ange källa och mål för att göra en mappning</h5>
        return mrtlocalizationSV;
    }

    const columns = useMemo<MRT_ColumnDef<IConceptMapGroupElement>[]>(
        //column definitions...
        () => [
            {
                accessorKey: 'display',
                enableEditing: false,
                header: 'Källa',
                enableColumnFilters: true,

            },


            {
                // @ts-ignore
                accessorFn: (originalRow) => {
                    // @ts-ignore
                    return (originalRow.target === undefined || originalRow.target.length === 0) ? undefined : originalRow.target[0].display;
                },
                enableEditing: false,
                header: 'Mål',
                enableColumnFilters: true,
            },
            {
                accessorFn: (originalRow) => {
                    // @ts-ignore
                    return (originalRow.target === undefined || originalRow.target.length === 0) ? undefined : originalRow.target[0].equivalence;
                },
                enableEditing: true,
                equivalence: 'equivalence',
                enableColumnFilters: true,
                filterVariant: 'multi-select',
                filterSelectOptions: filterCategoryOptions,
                header: 'Noggrannhet',
                Edit: ({cell, column, table, row}) =>
                    <RHookSelectForTable
                        arrayname={"group[0].element[" + row.id + "].target"}
                        name={"equivalence"}
                        index={"0"}
                        methods={props.methods}
                        options={categoryOptions}/>

            },

            {
                accessorFn: (originalRow) => {
                    // @ts-ignore
                    return (originalRow.target === undefined || originalRow.target.length === 0) ? undefined : originalRow.target[0].comment;
                },
                enableEditing: true,
                enableColumnFilters: false,
                header: 'Kommentar',
                Edit: ({cell, column, table, row}) =>

                    <RHookFormTextFieldForTable
                        arrayname={"group[0].element[" + row.id + "].target"}
                        fieldname={"comment"}
                        index={"0"}
                        methods={props.methods}
                    />
                ,
            },
        ],
        [],
    );

    const commonTableProps: Partial<MaterialReactTableProps<IConceptMapGroupElement>> & {
        columns: MRT_ColumnDef<IConceptMapGroupElement>[];
    } = {
        columns,
    };

    const handleSaveCell = (rowIndex: number, columnId: string, value: any) => {
        // @ts-ignore
        props.sourceTableData[rowIndex][columnId as keyof ConceptMapTableRowObj] = value;
        props.setSourceTableData([...props.sourceTableData]); //re-render with new data
    };

    return (
        <div>
            <br/><br/><br/>

            <MaterialReactTable
                {...commonTableProps}
                enableTopToolbar={false}
                enableHiding={false}
                autoResetPageIndex={false}
                columns={columns}
                data={props.sourceTableData}
                enableRowOrdering
                enableSorting={false}
                enableRowDragging={true}
                enableEditing={true}
                editDisplayMode="table"
                enableRowSelection
                onRowSelectionChange={props.setSourceRowSelections}
                state={{rowSelection: props.sourceRowSelections}}
                localization={getLocalizationSVtt()}

                displayColumnDefOptions={{
                    'mrt-row-drag': {
                        header: '',
                        size: 10,
                    },
                }}

                icons={{
                    MoreVertIcon: () => <ImportExport/>,
                    MoreHorizIcon: () => <MoreVertIcon/>,
                }}

                muiSelectCheckboxProps={{
                    color: 'success',
                    className: "ids-checkbox",
                    /*  classes: {root: "ids-checkbox"}*/
                    sx: muiSelectCheckboxPropsStyle
                }}
                table={props.sourceTableInstanceRef}

                muiTableHeadRowProps={{
                    sx: muiTableHeadRowPropsStyle
                }}

                muiTableProps={{
                    sx: muiTablePropsSixColumnStyle
                }}

                muiTableBodyProps={{
                    sx: muiTableBodyPropsStyle,
                    //     children: <React.Fragment>{renderCustomNoDataComponent()}</React.Fragment>
                }}


                muiRowDragHandleProps={({row, table}) => ({
                    onDragEnd: () => {
                        const {draggingRow, hoveredRow} = table.getState();
                        if (hoveredRow && draggingRow) {
                            let hoveredRowIndex = (hoveredRow as MRT_Row<ConceptMapTableRowObj>).index;
                            props.sourceTableData.splice(
                                hoveredRowIndex,
                                0,
                                props.sourceTableData.splice(draggingRow.index, 1)[0],
                            );
                            props.setSourceTableData([...props.sourceTableData]);
                        }
                        props.setHoveredTable(null);
                    },

                    sx: {
                        outline: undefined,
                    },
                })}


                muiTableBodyRowProps={({row, table}) => ({
                    sx: {
                        outline: (props.lastTableSourceRowDroppedOn === row.index && props.draggingRow !== null) ? '2px dashed green' : undefined,
                        borderRadius: 'inherit'
                    },
                })}

                muiTableBodyCellProps={({cell, row, table}) => ({

                    onClick: () => {

                        // @ts-ignore
                        //  const rowSelection: RowSelectionTableState = props.sourceTableInstanceRef.current.getState().rowSelection;

                        if (props.targetRowSelectionData !== undefined && props.targetRowSelectionData !== null) {

                            if (props.sourceTableData[row.index].target && props.sourceTableData[row.index].target.length > 0) {
                                props.sourceTableData[row.index].target[0].code = props.targetRowSelectionData.target;
                                props.sourceTableData[row.index].target[0].display = props.targetRowSelectionData.targetdisplay;
                            } else {

                                props.sourceTableData[row.index].target = [{
                                    code: props.targetRowSelectionData.target,
                                    display: props.targetRowSelectionData.targetdisplay
                                }]
                            }

                            var array = [...props.sourceTableData]; // make a separate copy of the array
                            props.setSourceTableData(array);

                            props.setHoveredTable(null);
                            props.setTargetRowSelectionData(null);
                        }
                    },

                    onDragEnter: () => {
                        if (props.lastTableSourceRowDroppedOn !== row.index) {
                            props.setLastTableSourceRowDroppedOn(row.index)
                        }
                    },
                })}


                muiTablePaperProps={{
                    onDragEnter: () => props.setHoveredTable('table-source'),
                    elevation: 0, //change the mui box shadow
                }}
            />
            <br/><br/><br/>
        </div>

    );
};

export default CMTableSource;
