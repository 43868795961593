//@ts-nocheck
import React, {FC, useState} from 'react';
import {IDSButton, IDSButtonGroup, IDSCol, IDSContainer, IDSLink, IDSRow} from "@inera/ids-react";
import {useFormContext} from "react-hook-form";
import RHookFormTextFieldSpan from "../../common/rhookform/RHookFormTextFieldSpan";
import Name from "../meta/Name";
import VersionDescription from "./VersionDescription";
import RetireReason from "./RetireReason";
import {extensionRetireReasonSystemUrl} from "../../../model/defaultvalues/Constant";

let renderCount = 0;

interface VersionStatusFormProps {
    closeDialog: any,
    requestedStatus: string
}


export const VersionStatusForm: FC<VersionStatusFormProps> = ({
                                                                  closeDialog,
                                                                  requestedStatus
                                                              }) => {

        const methods = useFormContext();

        const submitForm = (requestedStatus: string) => {

            methods.setValue("status", requestedStatus);
            let hasErrors = false;

            if (requestedStatus === "retired") {
                const extensions = methods.getValues("extension");
                if (extensions !== undefined && Array.isArray(extensions)) {
                    for (let i = 0; i < extensions.length; i++) {
                        let extension = extensions[i];
                        if (extension.url !== undefined && extension.url === extensionRetireReasonSystemUrl) {
                            if (extension.valueString === undefined || extension.valueString.trim().length == 0
                                || extension.valueString.length < 10) {
                                hasErrors = true
                                break;
                            }
                        }
                    }
                } else {
                    hasErrors = true;
                }
            }

            if (hasErrors == false) {
                closeDialog(true);
            }
        }


        const getText = () => {
            if (requestedStatus === "active") {
                return (<div className="container">
                    <p>Att aktivera artefakten innebär att den får versionsstatus aktiv. </p>
                    <p>Detta signalerar att artefakten är klar för användning.</p>
                    <p>Det går inte att göra ändringar i artefakten efter den har aktiverats.</p>
                </div>)
            } else if (requestedStatus === "retired") {
                return (
                    <div>
                        <div className="container">
                            <p>Att avställa artefakten innebär att den får versionsstatus avställd. </p>
                            <p>Det går inte att ändra versionsstatus i artefakten efter den har avställts.</p>
                        </div>
                        <p><RetireReason/></p>
                    </div>
                )
            }
        }

        renderCount++;

        return (
            <div style={{width: "100%"}}>
                <IDSContainer gutterless={true} style={{width: "100%"}}>
                    <IDSRow justify="space-between">
                        <IDSCol>
                            <div className="container">
                                <strong>Systemnamn:</strong> {methods.getValues("name")}<br/>
                                <strong>Version:</strong> {methods.getValues("version")}<br/>
                                <br/>
                            </div>
                        </IDSCol>
                    </IDSRow>
                    <IDSRow justify="space-between">
                        <IDSCol>
                            <div>
                                {getText()}
                                <br/><br/>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <IDSButtonGroup>
                                        <IDSLink underlined>
                                            <a href="#" onClick={() => {
                                                closeDialog(false);
                                            }}>AVBRYT </a>
                                        </IDSLink>

                                        <IDSButton type="button">
                                            <button type="button" onClick={() => {
                                                submitArtefact();
                                            }}>
                                                BEKRÄFTA
                                            </button>
                                        </IDSButton>
                                    </IDSButtonGroup>
                                </div>
                            </div>
                        </IDSCol>
                    </IDSRow>
                </IDSContainer>
            </div>
        );
    }
;

export default VersionStatusForm;