import React, {FC, useState} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import {IDSDialog, IDSLink} from "@inera/ids-react";
import {getArtifactSwedishLabel} from "../../CommonFunctions";

interface TTBaseDialogProps {
    setShowDialog: any,
    showDialog: boolean,
    dialogRef: any,
    headingText: string | undefined,
    content : any
}

export const TTBaseDialog: FC<TTBaseDialogProps> = ({
                                                        setShowDialog,
                                                        showDialog,
                                                        dialogRef,
                                                        headingText,
                                                        content,
                                                    }) => {

    const [resetDialog, setResetDialog] = useState(false);
    const closeDialog = () => setShowDialog(false);


    return (
        <div>
            <IDSDialog nooverlay={false} persistent={true} dismissible={true}
                       show={showDialog.toString()}
                       onClosed={() => {
                       }}>
                <IDSLink slot="trigger">
                    <a href="javascript:void(0)"
                       tabIndex={-1}
                      // trigger="true"
                       ref={dialogRef} style={{color: '#FFF'}}></a>
                </IDSLink>
                <h1 className="ids-heading-xxl" slot="headline" tabIndex={-1}>
                    {headingText}
                </h1>
                <p style={{color: '#FFF'}}>..................................................................................................................................</p>
                <>
                    {content}
                </>
            </IDSDialog>
        </div>
    );
};
export default TTBaseDialog;