import React, {FC} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {IDSInput} from "@inera/ids-react";
import RHookFormValidationMessage from "../validation/RHookFormValidationMessage";
import {removeTabsInValue} from "./FieldUtil";
import {getHelpTextPopOverIfAny} from "../../../services/localstorageconcepts/HelpTextService";
import {getErrorType} from "./RHookFormMessageUtil";
import {hasValidationInternalMetaDataYupErrorRules} from "../validation/ValidationInternalMetaDataYupErrorRulesUtil";
import {ERROR_EXTERNAL_NOTE_LEVEL} from "../validation/external/ValidationExternalNotificationService";
import {getIDSInputInvalidValue} from "../validation/RHookFormValidationUtil";

interface ReactHookFormTextFieldIneraProps {
    label: string;
    name: string;
    methods: UseFormReturn;
    disabled: boolean;
    enableValidationWhenFieldDisabled?: boolean;
    helpTextCode?: string
}

const RHookFormTextField: FC<ReactHookFormTextFieldIneraProps> = ({
                                                                      label,
                                                                      name,
                                                                      methods,
                                                                      disabled,
                                                                      enableValidationWhenFieldDisabled,
                                                                      helpTextCode
                                                                  }: ReactHookFormTextFieldIneraProps) => {

    const getDisabled = () => {
        if (disabled !== undefined && disabled) {
            return disabled;
        } else {
            return false;
        }
    }

    const isValid = () => {
        if (methods.formState.errors[name]?.message === undefined) {
            return true;
        } else {
            return false;
        }
    }

    function hasNumber(name: string) {
        return /\d/.test(name);
    }

    function isMessageDisabled() {
        return enableValidationWhenFieldDisabled !== undefined && enableValidationWhenFieldDisabled ? false : disabled;
    }

    return (
        <div className={'container'} key={"container_textinput_" + name}>
            <>
                <IDSInput invalid={getIDSInputInvalidValue(name, methods)} novalidation>
                    <label>{label}{getHelpTextPopOverIfAny(helpTextCode, disabled)}</label>
                    <input type="text"
                           {...methods.register(name, {setValueAs: (value: string) => removeTabsInValue(value)})}
                           defaultValue={""}
                           key={"textinput_" + name}
                           disabled={getDisabled()}
                    />

                    <RHookFormValidationMessage name={name} editDisabled={isMessageDisabled()}/>
                </IDSInput>
            </>
            <br/>
        </div>
    );
};


export default RHookFormTextField;