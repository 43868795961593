import React, {useState} from "react";
import '@mobiscroll/react-lite/dist/css/mobiscroll.min.css';
import '@inera/ids-core/components/form/radio/register';
import '@inera/ids-core/components/form/error-message/register';
import '@inera/ids-core/components/breadcrumbs/register';
import '@inera/ids-core/components/list/register';
import '@inera/ids-core/components/badge/register';
import '@inera/ids-core/components/link/register';

import '@inera/ids-core/components/notification/badge/register';
import SearchBase from "./SearchBase";
import {codesystem, TAG_SECTOR_SYSTEM_URL} from "../../../model/defaultvalues/Constant";
import {VERTICAL_BAR_URL_ENCODING} from "../../../constants";

const Search = (props) => {
    const [publisher, setPublisher] = useState("");
    const [sector, setSector] = useState("");
    const [version, setVersion] = useState("");
    const [status, setStatus] = useState("");
    const [artifactType, setArtifactType] = useState(props.artifactType !== undefined ? props.artifactType : codesystem);
    const [nextSearchResultLink, setNextSearchResultLink] = useState([]);


    const filter = (params) => {

        if (sector) {
            params._tag = TAG_SECTOR_SYSTEM_URL  + VERTICAL_BAR_URL_ENCODING +  sector;
        }

        if (publisher) {
            params["publisher:contains"] = publisher;
        }

        if (status) {
            params["status"] = status;
        }

        if (version && version !== "") {
            params["version:text"] = version;
        }
    };

    const onChangeSector = (e) => {
        setNextSearchResultLink([]);
        setSector(e.target.value);
    };

    const onChangePublisher = (e) => {
        setNextSearchResultLink([]);
        setPublisher(e.target.value);
    };

    const onChangeVersion = (e) => {
        setNextSearchResultLink([]);
        setVersion(e.target.value);
    };

    const onChangeStatus = (e) => {
        setNextSearchResultLink([]);
        setStatus(e.target.value);
    };

    const onChangeArtifactType = (e) => {
        setNextSearchResultLink([]);
        setArtifactType(e.target.value);
    };

    const clearFilters = () => {
        setPublisher("");
        setVersion("");
        setSector("");
        setStatus("");
    };


    return (
        <SearchBase
            clearFilters={clearFilters}
            artifactType={artifactType}
            setArtifactType={setArtifactType}
            belongsTo={props.belongsTo}
            filter={filter}
            getCodeSystemForValue={props.getCodeSystemForValue}
            /* TODO getCodeSystemForValueSet getCodeSystemForArtifact duplicated code? */
            getCodeSystemForValueSet={props.getCodeSystemForValueSet}
            getCodeSystemForArtifact={props.getCodeSystemForArtifact}
            tabKey={props.tabKey}
            loadSelectedCodeSystemForValueSet={props.loadSelectedCodeSystemForValueSet}
            setLoadSelectedCodeSystemForValueSet={props.setLoadSelectedCodeSystemForValueSet}
            nextSearchResultLink={nextSearchResultLink}
            setNextSearchResultLink={setNextSearchResultLink}
            artifactTypeChoice={props.artifactTypeChoice}
            onChangeArtifactType={onChangeArtifactType}
            sector={sector}
            publisher={publisher}
            version={version}
            status={status}
            onChangeSector={onChangeSector}
            onChangePublisher={onChangePublisher}
            onChangeVersion={onChangeVersion}
            onChangeStatus={onChangeStatus}
            getValueSetCodeSystemIds={props.getValueSetCodeSystemIds}
        />
    );
};

export default Search;
