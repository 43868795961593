import React, {FC, useEffect, useState} from 'react';
import '@inera/ids-core/components/link/register';
import 'beautiful-react-diagrams/styles.css';

import {IDSContainer, IDSCol, IDSRow, IDSSelect, IDSSpinner} from "@inera/ids-react";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import Client from "fhir-kit-client";
import {fhirUrl, SNOMED_CT_URL} from "../../../model/defaultvalues/Constant";
import ConceptSNOMEDRefsetTable from "../../../components/artifact/concept/table/ConceptSNOMEDRefsetTable";
import {IRHookSelectOption} from "../../../components/common/rhookform/IRHookSelectOption";
import Spinner from "react-bootstrap/Spinner";
import {dynamicSort} from "../../../components/common/CommonFunctions";
import {SearchTotalMessage} from "../../../components/artifact/search/SearchTotalMessage";
import {
    getSwedishRecommendedFromDesignations
} from "../transformer/synonym/SynonymSwedishLanguageTransformer";
import IConceptSearchResultItem from "../search/model/IConceptSearchResultItem";
import {collectContains} from "../transformer/SnomedTransformer";
import {getBaseId} from "../util/SnomedUrlUtil";

let renderCount = 0;

const fhirClient = new Client({
    baseUrl: fhirUrl,
    customHeaders: {
        "Content-Type": "application/fhir+json",
        "Accept": "application/fhir+json"
    }
});


interface SnomedTabPanelRefsetProps {
    id: string,
    version: string
    snomedConceptsToAdd?: any[],
    handleNodeClick: any,
    handleChange?: any,
    tabKey?: number,
    codeSystemIndex?: number,
    changeFromMenu?: string,
}

const SnomedTabPanelRefset: FC<SnomedTabPanelRefsetProps> = ({
                                                                 id,
                                                                 version,
                                                                 snomedConceptsToAdd,
                                                                 handleNodeClick,
                                                                 handleChange,
                                                                 tabKey,
                                                                 codeSystemIndex,
                                                                 changeFromMenu
                                                             }) => {

    const expandRefsetUrlPart01 = 'ValueSet/$expand?url=' + SNOMED_CT_URL + "/" + getBaseId(id) + '?fhir_vs=refset';
    //some expand requests towards Ontoserver fail if count is not set
    const expandRefsetUrlCount = "900";
    const expandRefsetUrlPart02 = "&includeDesignations=true&count=" + expandRefsetUrlCount +
        "&elements=expansion.contains.code,expansion.contains.display,expansion.contains.fullySpecifiedName,expansion.contains.active";


    // Set state
    const [isRequestingSnomedRefset, setIsRequestingSnomedRefset] = useState(false);
    const [snomedRefSet, setSnomedRefSet] = useState([]);
    const [snomedRefsetOptions, setSnomedRefsetOptions] = useState<IRHookSelectOption[]>([]);


    const [refSetTableData, setRefSetTableData] = useState<IConceptSearchResultItem[]>([]);

    const [selectedRefset, setSelectedRefset] = useState("");
    const [total, setTotal] = useState(undefined);
    const [selectedRefsetTitle, setSelectedRefsetTitle] = useState("");


    useEffect(() => {
        setIsRequestingSnomedRefset(true);
        fhirClient.request(expandRefsetUrlPart01 +
            expandRefsetUrlPart02
            //  ,{options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}}}
        )
            .then(response => {
                //  @ts-ignore
                setSnomedRefSet(response.expansion.contains);
                setIsRequestingSnomedRefset(false);
                //  @ts-ignore
                loadRefSetOptions(response.expansion.contains);
            })
            .catch(e => {
                console.error("FhirClient SNOMED ValueSet/$expand Error Response" + JSON.stringify(e));
            });

    }, []);


    const getRefSetOptions = (refsetToConcertToOption ?: []) => {
        const refsetList = refsetToConcertToOption === undefined ? snomedRefSet : refsetToConcertToOption;
        if (snomedRefsetOptions.length == 0) {
            let refSetOptions: IRHookSelectOption[] = [];
            //  @ts-ignore
            for (let concept of refsetList) {
                let swedishValue = undefined;
                //  @ts-ignore
                swedishValue = getSwedishRecommendedFromDesignations(concept.designation);

                //  @ts-ignore
                refSetOptions.push({
                    //  @ts-ignore
                    label: swedishValue === undefined ? concept.display : swedishValue,
                    //  @ts-ignore
                    value: concept.code
                })
            }
            let sortedList = refSetOptions.sort(dynamicSort("label"));
            return sortedList;
        } else {
            return snomedRefsetOptions;
        }
    }

    const loadRefSetOptions = async (refsetToConcertToOption: []) => {
        let sortedList = await getRefSetOptions(refsetToConcertToOption);
        setSnomedRefsetOptions(sortedList);
    }


    const getRefSetTableData = (contains: any) => {
        let concepts: ICodeSystemConcept[] = [];
        //  @ts-ignore
        for (let concept of contains) {
            let swedishValue = getSwedishRecommendedFromDesignations(concept.designation);

            //  @ts-ignore
            concepts.push({display: swedishValue === undefined ? concept.display : swedishValue, code: concept.code})
        }
        return concepts;
    }


    // @ts-ignore
    const handleSelect = (event: any) => {
        setTotal(undefined);
        setSelectedRefset(event.target.value);
        setIsRequestingSnomedRefset(true);
        fhirClient.request(expandRefsetUrlPart01 + "/" + event.target.value +
            "&system-version=" + SNOMED_CT_URL + "|" + SNOMED_CT_URL + "/" + getBaseId(id) + expandRefsetUrlPart02,
            //   {options: {headers: {'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)}}}
        )
            .then(response => {

                //  @ts-ignore
                setTotal(response.expansion.total)

                //  @ts-ignore
                if (response.expansion.total != 0) {

                    //  @ts-ignore
                    //  let refsetConcepts: ICodeSystemConcept[] = getRefSetTableData(response.expansion.contains);
                    //  @ts-ignore
                    const concepts: IConceptSearchResultItem[] = collectContains(response);


                    if (snomedConceptsToAdd !== undefined) {
                        for (let conceptToAdd of snomedConceptsToAdd) {

                            var rowSelectionFoundInConceptsToAdd = concepts.filter(refsetItem => {
                                if (refsetItem.code === conceptToAdd.code)
                                    return refsetItem;
                            })

                            if (rowSelectionFoundInConceptsToAdd.length == 1) {
                                //  @ts-ignore
                                rowSelectionFoundInConceptsToAdd[0].selected = true;
                            }
                        }
                    }
                    //  @ts-ignore
                    setRefSetTableData(concepts);
                } else {
                    setRefSetTableData([]);
                }

                setIsRequestingSnomedRefset(false);
                var selectedRefsetWithLabel = snomedRefsetOptions.find(item => item.value === event.target.value);
                if (selectedRefsetWithLabel !== undefined) {
                    setSelectedRefsetTitle(selectedRefsetWithLabel.label);
                }

            })
            .catch(e => {
                console.error("FhirClient SNOMED setSnomedRefSetChoosenParent Error Response" + JSON.stringify(e));
            });


    };


    const handleRefSetRowClick = async (code: string, display: string, rowid: string) => {
        const concept: IConceptSearchResultItem = await refSetTableData[Number(rowid)];
        handleNodeClick(concept, version)
    }

    renderCount++;

    return (
        <div>
            <IDSContainer gutterless={true}>
                <IDSRow justify="space-between">
                    <IDSCol>

                        <IDSContainer gutterless={true}>
                            <IDSRow justify="space-between">
                                <IDSCol>
                                    <div>
                                        <form id="snomed-form">
                                            <div className={'containerRefSetOptions'} key={Math.random()}>
                                                <IDSSelect>
                                                    <select value={selectedRefset} onChange={handleSelect}
                                                            defaultValue={""}>
                                                        <option value={""} disabled={true} key={Math.random()}>Välj i
                                                            listan
                                                        </option>
                                                        {getRefSetOptions().map((item) => (
                                                            <option value={item.value}
                                                                    key={Math.random()}>{item.label}</option>
                                                        ))}
                                                    </select>
                                                </IDSSelect>
                                                {isRequestingSnomedRefset &&
                                                    <Spinner animation="border" role="status">
                                                        {/*<span className="visually-hidden">Hämtar SNOMED Refset...</span>*/}
                                                        <IDSSpinner live="polite" srtext="Hämtar SNOMED Refset..."/>
                                                    </Spinner>
                                                }
                                            </div>
                                        </form>
                                        {(!isRequestingSnomedRefset && selectedRefset !== "" && total == 0) &&
                                            <IDSContainer gutterless={true}>
                                                <IDSRow>
                                                    <IDSCol cols="12">
                                                        <SearchTotalMessage
                                                            searchPerformed={!isRequestingSnomedRefset}
                                                            total={0}
                                                            lastSearchPhrase={selectedRefsetTitle}
                                                        />
                                                    </IDSCol>
                                                    <IDSCol cols="5"> &nbsp;
                                                    </IDSCol>
                                                </IDSRow>
                                            </IDSContainer>
                                        }
                                        {(refSetTableData.length > 0) &&
                                            <ConceptSNOMEDRefsetTable
                                                snomedId={id}
                                                version={version}
                                                data={refSetTableData}
                                                loadingRows={isRequestingSnomedRefset}
                                                setLoadingRows={setIsRequestingSnomedRefset}
                                                snomedConceptsToAdd={snomedConceptsToAdd}
                                                handleNodeClick={handleRefSetRowClick}
                                                handleChange={handleChange}
                                                tabKey={tabKey}
                                                codeSystemIndex={codeSystemIndex}
                                                changeFromMenu={changeFromMenu}
                                            />
                                        }
                                    </div>
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>

                    </IDSCol>
                </IDSRow>
            </IDSContainer>
        </div>
    );
};

export default SnomedTabPanelRefset;
