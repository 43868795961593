import React, {useRef, useState} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import {permissionToEdit} from "../../../../user/PermissionUtil";
import Spinner from "react-bootstrap/Spinner";
import {IDSButton, IDSButtonGroup, IDSSpinner} from "@inera/ids-react";
import StopEditDialog from "./StopEditDialog";
import {useFormContext} from "react-hook-form";
import {conceptmap, valueset} from "../../../../model/defaultvalues/Constant";

const UpdateButton = (props) => {

        const methods = useFormContext();
        const stopEditDialogRef = useRef("stop-edit-dialogRef");
        const [stopEditShowDialog, setStopEditShowDialog] = useState(false);

        const displayStopEditingDialog = () => {
            if (methods.formState.isDirty) {
                setStopEditShowDialog(true);
            } else if (isCodeArraysBelongingToFormDirty()) {
                setStopEditShowDialog(true);
            } else {
                if (props.artefactType === valueset || props.artefactType === conceptmap) {
                    //to reload if Valuset has been saved with added or removed codesystems
                    props.changeEditDisabled(true);
                } else {
                    props.changeEditDisabled();
                }
            }
        }

        const isCodeArraysBelongingToFormDirty = () => {
            if (props.artefactType === valueset || props.artefactType === conceptmap) {
                return props.isCodesBelongingToFormDirty();
            } else {
                return false;
            }
        }

        return (
            <div>
                <StopEditDialog dialogRef={stopEditDialogRef}
                                showDialog={stopEditShowDialog}
                                setShowDialog={setStopEditShowDialog}
                                changeEditDisabled={props.changeEditDisabled}/>

                {permissionToEdit(props.methods) && !props.editDisabled &&
                    <IDSButtonGroup>
                        <IDSButton type="button" secondary size="s">
                            <button type="button" onClick={() => displayStopEditingDialog()}>
                                SLUTA REDIGERA
                            </button>
                        </IDSButton>

                        <button type="submit" color="primary" className="ids-button">
                            SPARA
                        </button>

                        {/*           <IDSButton type="submit" size="s" color="primary">
                        Spara
                    </IDSButton>*/}
                    </IDSButtonGroup>
                }
                <br/>
                {props.isRequesting &&
                    <Spinner animation="border" role="status">
                        <IDSSpinner live="polite" srtext="Uppdaterar..."/>
                    </Spinner>
                }
            </div>
        );
    }
;
export default UpdateButton;
