export const HELP_META_TITLE : string = "HELP_META_TITLE";
export const HELP_META_SYSTEM_NAME: string = "HELP_META_SYSTEM_NAME";
export const HELP_META_BESKRIVNING: string = "HELP_META_BESKRIVNING";
export const HELP_META_FORVALTARE: string = "HELP_META_FORVALTARE";
export const HELP_META_INNEHALLSANSVARIG: string = "HELP_META_INNEHALLSANSVARIG";
export const HELP_META_TYPE_OF_IDENTIFIER: string = "HELP_META_TYPE_OF_IDENTIFIER";
export const HELP_META_IDENTIFIER: string = "HELP_META_IDENTIFIER";
export const HELP_VERSION_NAME: string = "HELP_VERSION_NAME";
export const HELP_VERSION_DESCRIPTION: string = "HELP_VERSION_DESCRIPTION";
export const HELP_IMPORT_METHOD_CLEAR_N_REPLACE: string = "HELP_IMPORT_METHOD_CLEAR_N_REPLACE";
export const HELP_IMPORT_METHOD_ADD_OR_UPDATE: string = "HELP_IMPORT_METHOD_ADD_OR_UPDATE";
export const HELP_SEARCH_SEARCH_ON: string = "HELP_SEARCH_SEARCH_ON";
export const HELP_SEARCH_SEARCH_VALUE: string = "HELP_SEARCH_SEARCH_VALUE";
export const HELP_SEARCH_FILTER_SECTOR: string = "HELP_SEARCH_FILTER_SECTOR";
export const HELP_SEARCH_FILTER_FORVALTARE: string = "HELP_SEARCH_FILTER_FORVALTARE";
export const HELP_SEARCH_FILTER_VERSION: string = "HELP_SEARCH_FILTER_VERSION";
export const HELP_SEARCH_FILTER_VERSION_STATUS: string = "HELP_SEARCH_FILTER_VERSION_STATUS";
export const HELP_CREATE_MODAL_TITLE: string = "HELP_CREATE_MODAL_TITLE";
export const HELP_CREATE_MODAL_SYSTEM_NAME: string = "HELP_CREATE_MODAL_SYSTEM_NAME";