import React, {useMemo, useRef} from 'react';
import {MaterialReactTable,
    MaterialReactTableProps,
    MRT_ColumnDef,
    MRT_Row, MRT_TableInstance,
} from 'material-react-table';
import {MRT_Localization_SV} from 'material-react-table/locales/sv';
import {
    muiTableBodyPropsStyle,
    muiTableHeadRowPropsStyle,
    muiTablePropsFiveColumnStyle, muiTablePropsThreeColumnStyle, muiTableRowPropsStyle,
} from "../../../../styles/TTStyles";
import {getHierarchyAsString} from "../../../common/rhookform/RHookFormArrayHierarchyFunction";
import RHookFormTextFieldForTableController from "../../../common/rhookform/RHookFormTextFieldForTableController";

import {MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportExport from '@mui/icons-material/ImportExport';
import RowActionsMenu from "./RowActionsMenu";
import '@inera/ids-core/components/link/register';


import {IDSIconPlus, IDSLink} from "@inera/ids-react";
import {ICodeSystemConcept} from "fhir-typescript-models/dist/src/models/fhir/internal";
import {conceptList} from "../../../../model/defaultvalues/CodeSystemDefaultFormValue";
import {errorValidateCode} from "../CodeErrorValidation";
import {warnValidateCode} from "../CodeWarnValidation";
import {infoValidateCode} from "../CodeInfoValidation";
import RHookFormTextAreaForTableController from "../../../common/rhookform/RHookFormTextAreaForTableController";
import {validateInfoDefinition} from "../DefinitionValidation";
import {warnValidateDisplay} from "../DisplayWarnValidation";
import {infoValidateDisplay} from "../DisplayInfoValidation";


let renderCount = 0;

const ConceptTable = (props: any) => {


    const arrayname = "concept"


    const codeErrorValidation = (value: string, prevValue: string, rowindex: string, hasRowAllEmptyValues?: boolean) => {
        return errorValidateCode(value, prevValue, rowindex, props.errorMessageList, props.handleErrorMessageList, props.methods, hasRowAllEmptyValues);
    }

    const codeWarnValidation = (value: string, prevValue: string, rowindex: string, hasRowAllEmptyValues?: boolean) => {
        return warnValidateCode(value, prevValue, rowindex, props.warnMessageList, props.handleWarnMessageList, props.methods, hasRowAllEmptyValues);
    }

    const codeInfoValidation = (value: string, prevValue: string, rowindex: string, hasRowAllEmptyValues?: boolean) => {
        return infoValidateCode(value, prevValue, rowindex, props.infoMessageList, props.handleInfoMessageList, props.methods, hasRowAllEmptyValues);
    }

    const displayWarnValidation = (value: string, prevValue: string, rowindex: string, hasRowAllEmptyValues?: boolean) => {
        return warnValidateDisplay(value, prevValue, rowindex, props.warnMessageList, props.handleWarnMessageList, props.methods, hasRowAllEmptyValues);
    }

    const displayInfoValidation = (value: string, prevValue: string, rowindex: string, hasRowAllEmptyValues?: boolean) => {
        return infoValidateDisplay(value, prevValue, rowindex, props.infoMessageList, props.handleInfoMessageList, props.methods, hasRowAllEmptyValues);
    }

    const definitionInfoValidation = (value: string, rowindex: string, hasRowAllEmptyValues?: boolean) => {
        return validateInfoDefinition(value, rowindex, props.infoMessageList, props.handleInfoMessageList, props.methods, hasRowAllEmptyValues);
    }
    const getDataForTable = () => {
        if (props.data !== undefined) {
            return props.data;
        } else {

            const concepts = props.methods.getValues(arrayname);
            let data = (
                (concepts === undefined) || (concepts.length == 0) ||
                (concepts[0] == undefined || concepts[0].code == undefined || concepts[0].code === ""))
                ? conceptList : concepts;
            return data;
        }
    }


    renderCount++;

    /*   const resetAsyncForm = useCallback(async () => {
           setConceptHierarchy(getConceptHierarchy(props.data));
       }, []);*/

    const columnDefinitions: MRT_ColumnDef<ICodeSystemConcept>[] = [
        {
            accessorKey: 'code',
            header: 'Kod',
            // @ts-ignore
            enableColumnFilters: true,
            Edit: ({cell, column, table, row}) =>
                <RHookFormTextFieldForTableController editDisabled={false}
                                                      arrayname={arrayname}
                                                      fieldname={"code"}
                                                      index={row.id}
                                                      methods={props.methods}
                                                      error={codeErrorValidation}
                                                      warn={codeWarnValidation}
                                                      info={codeInfoValidation}
                                                      arrayUpdateMethod={props.update}
                                                      arrayAppendMethod={props.append}
                />
        },
        {
            accessorKey: 'display',
            header: 'Klartext',
            // @ts-ignore
            enableColumnFilters: true,
            Edit: ({cell, column, table, row}) =>
                <RHookFormTextFieldForTableController
                    editDisabled={false}
                    arrayname={arrayname}
                    fieldname={"display"}
                    index={row.id}
                    methods={props.methods}
                    warn={displayWarnValidation}
                    info={displayInfoValidation}
                    arrayUpdateMethod={props.update}
                    arrayAppendMethod={props.append}
                />
        },
        {
            accessorKey: 'definition',
            header: 'Beskrivning',
            // @ts-ignore
            enableColumnFilters: true,
            Edit: ({cell, column, table, row}) =>
                <RHookFormTextAreaForTableController
                    editDisabled={false}
                    arrayname={arrayname}
                    fieldname={"definition"}
                    index={row.id}
                    methods={props.methods}
                    info={definitionInfoValidation}
                    arrayUpdateMethod={props.update}
                />
        },
    ];

    const columns = useMemo<MRT_ColumnDef<ICodeSystemConcept>[]>(
        //column definitions...
        () => columnDefinitions,
        [],
        //end
    );


    const commonTableSourceProps: Partial<MaterialReactTableProps<ICodeSystemConcept>> & {
        columns: MRT_ColumnDef<ICodeSystemConcept>[];
    } = {
        columns,
        enableFullScreenToggle: false,
    };

    const tableInstanceRef = useRef<MRT_TableInstance<ICodeSystemConcept>>(null);

    const handleConceptDragDropHiararchyArray = (draggingRow: MRT_Row<ICodeSystemConcept>,
                                                 hoveredRow: MRT_Row<ICodeSystemConcept> | { id: string; } | null) => {

        let rhookFormDraggingRowHiararchyIndex = getHierarchyAsString(draggingRow.id, arrayname);
        let rhookFormHoveredHiararchyRowIndex;
        if (hoveredRow !== null && hoveredRow.id !== undefined) {
            rhookFormHoveredHiararchyRowIndex = getHierarchyAsString(hoveredRow.id, arrayname);
        }


        if (rhookFormHoveredHiararchyRowIndex !== undefined) {

            var draggingRowParentIndex = rhookFormDraggingRowHiararchyIndex.substring(0, rhookFormDraggingRowHiararchyIndex.lastIndexOf("["));
            var draggingRowConceptIndex = +(rhookFormDraggingRowHiararchyIndex.substring(rhookFormDraggingRowHiararchyIndex.lastIndexOf("[") + 1, rhookFormDraggingRowHiararchyIndex.length - 1));

            var hoveredRowParentIndex = rhookFormHoveredHiararchyRowIndex.substring(0, rhookFormHoveredHiararchyRowIndex.lastIndexOf("["));
            var hoveredRowConceptIndex = +(rhookFormHoveredHiararchyRowIndex.substring(rhookFormHoveredHiararchyRowIndex.lastIndexOf("[") + 1, rhookFormHoveredHiararchyRowIndex.length - 1));


            //  function useAsyncState(initialValue: string) {
            //TODO handle drag row in childen rows
            // console.log("handleConceptHiararchyArray, draggingRowParentIndex: " + JSON.stringify(draggingRowParentIndex))
            // console.log("handleConceptHiararchyArray, draggingRowConceptIndex: " + JSON.stringify(draggingRowConceptIndex))

            console.log("handleConceptHiararchyArray, hoveredRowParentIndex: " + JSON.stringify(hoveredRowParentIndex))
            console.log("handleConceptHiararchyArray, hoveredRowConceptIndex: " + JSON.stringify(hoveredRowConceptIndex))


            if (draggingRowParentIndex === hoveredRowParentIndex) {
                console.log("handleConceptHiararchyArray, dragging and hovering row same level")
                /*  const moveDraggingRow = async () => {*/
                console.log("handleConceptHiararchyArray, BEFORE conceptHierarchyAsString:" + JSON.stringify(props.conceptHierarchyAsString))

                //     await
                props.setConceptHierarchyAsString(hoveredRowParentIndex);
                console.log("handleConceptHiararchyArray, AFTER conceptHierarchyAsString:" + JSON.stringify(props.conceptHierarchyAsString))

                props.move(draggingRowConceptIndex, hoveredRowConceptIndex);
                /* }*/
            } else {
                //Move in different levels of hierarchy
                console.log("handleConceptHiararchyArray, dragging and hovering NOOOOT row same level")
                const addDraggingRow = async () => {
                    await props.setConceptHierarchyAsString(hoveredRowParentIndex);
                    props.insert(hoveredRowConceptIndex, draggingRow);
                }

                const removeDraggingRow = async () => {
                    await props.setConceptHierarchyAsString(draggingRowParentIndex);
                    props.remove(hoveredRowConceptIndex, hoveredRow);
                }

            }
        }
    };

    renderCount++;

    return (
        <div>
            <div>
                <>
                    <MaterialReactTable
                        {...commonTableSourceProps}
                        autoResetPageIndex={false}
                        columns={columns}
                        data={getDataForTable()}
                        enableRowOrdering={props.editDisabled ? false : true}
                        enableColumnFilters={true}
                        enableSorting={true}
                        localization={MRT_Localization_SV}
                        enableTopToolbar={false}
                        enableSelectAll={true}
                        enableEditing={props.editDisabled ? false : true}
                        enableHiding={false}
                        editDisplayMode="table"
                        enableRowActions={true}
                        positionActionsColumn="last"
                        enableExpanding={false}
                        enableExpandAll={false}

                        muiTableBodyRowProps={{
                            sx: muiTableRowPropsStyle
                        }}

                        muiTableHeadRowProps={{
                            sx: muiTableHeadRowPropsStyle
                        }}

                        muiTableProps={{
                            sx: muiTablePropsThreeColumnStyle
                        }}

                        muiTableBodyProps={{
                            sx: muiTableBodyPropsStyle
                        }}


                        muiTablePaperProps={{
                            elevation: 0, //change the mui box shadow
                        }}

                        icons={{
                            MoreVertIcon: () => <ImportExport/>,
                            MoreHorizIcon: () => <MoreVertIcon/>,
                        }}

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: '',
                                size: 10,
                            },
                            'mrt-row-drag': {
                                header: '',
                                size: 10,
                            },
                        }}

                        getSubRows={(originalRow) => {
                            return (
                                originalRow.concept
                            )
                        }}

                        renderRowActions={({row, table}) => (
                            <div>
                                {(!props.editDisabled) &&
                                    <RowActionsMenu rowid={row.index} table={table} remove={props.remove}
                                                    append={props.append}/>
                                }
                            </div>
                        )}

                        renderRowActionMenuItems={({row}) => [
                            <MenuItem onClick={() => console.info('Edit')}>Edit</MenuItem>,
                            <MenuItem onClick={() => console.info('Delete')}>Delete</MenuItem>
                        ]}

                        muiColumnActionsButtonProps={{
                            children: <ExpandMore/>
                        }}

                        muiRowDragHandleProps={({table, row}) => ({
                            onDragEnd: () => {
                                const {draggingRow, hoveredRow} = table.getState();
                                if ((hoveredRow && draggingRow) && (hoveredRow.id !== undefined)) {
                                    if (!draggingRow.id.includes(".") && !hoveredRow.id.includes(".")) {
                                        // @ts-ignore
                                        props.move(draggingRow.id, hoveredRow.id);
                                    } else {
                                        // @ts-ignore
                                        handleConceptDragDropHiararchyArray(draggingRow, hoveredRow);
                                    }
                                }
                            },
                        })}
                    />
                </>
            </div>

            <div className={'container'}>


                {(!props.editDisabled && props.appendNewRow !== undefined) &&
                    <div>
                        <IDSLink color="green">
                            <IDSIconPlus/>
                            <a role="button" onClick={() => {
                                var length = props.methods.getValues(arrayname).length;
                                if (length != 0) {
                                    const lastRowConcept = props.methods.getValues(arrayname)[length - 1];
                                    if (lastRowConcept.code.trim() !== "") {
                                        props.appendNewRow({
                                            code: "",
                                            display: "",
                                            definition: ""
                                            /*      ,
                                                  property: [{code:"source", value:""}]*/
                                        });
                                    }
                                }
                            }}>Lägg till rad</a>
                        </IDSLink>
                    </div>
                }

                <br/>

                {/*TODO more links*/}
                {/*               <IDSLink color="green" underlined="true">
                    <IDSIcon name="plus"></IDSIcon>
                        <a href="#" onClick={props.append({
                            code: "",
                            display: "",
                            definition: ""

                        })}>Link kjihidjglskdfjg</a>

                </IDSLink>*/}


            </div>
        </div>
    );
};

export default ConceptTable;
