import React from "react";
import Client from 'fhir-kit-client';
import {codesystem, fhirUrl} from "../../model/defaultvalues/Constant";

export const loadLocalStorageItems = async (codeSystemId : string, localStorageId : string) => {

    const client = new Client({baseUrl: fhirUrl});
    await
        client.read({resourceType: codesystem, id: codeSystemId})
            .then(response => {
                if (response !== undefined) {
                    const sectors = JSON.stringify(response.concept);
                    localStorage.setItem(localStorageId, JSON.stringify(response.concept));
                    return response.concept;
                }
            })
            .catch(e => {
                console.error(JSON.stringify(e));
            });
};

export const getConceptsFromLocalStorage = (codeSystemId : string, localStorageId : string): [] => {
    let localStorageItemAsString: string | null = localStorage.getItem(localStorageId);

    if (localStorageItemAsString === undefined ||
        localStorageItemAsString === null || localStorageItemAsString.trim().length == 0) {
        loadLocalStorageItems(codeSystemId, localStorageId);
        localStorageItemAsString = localStorage.getItem(localStorageId);
    }

    if (localStorageItemAsString !== null && localStorageItemAsString !== undefined) {
        return JSON.parse(localStorageItemAsString || "[]");
    } else {
        return [];
    }
};
