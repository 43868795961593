import React from "react";
import {help_texts_codesystem_id} from "../../model/defaultvalues/Constant";
import {HELP_TEXTS} from "../../constants";
import {getConceptsFromLocalStorage, loadLocalStorageItems} from "./ConceptLStorageBaseServicel";
import IConceptBasic from "../../model/IConceptBasic";
import HelpTextPopOver from "../../components/common/page/popover/HelpTextPopOver";

export const getHelpTextDisplay = (code: string): string | null => {
    let concept = getHelpTextConcept(code);
    if (concept !== undefined) {
        return concept.display;
    } else {
        return null;
    }
};

const getHelpTextConcept = (code: string): IConceptBasic => {
    let helpTextConceptArray: IConceptBasic[] = getHelpTextConcepts();
    return helpTextConceptArray.find(o => o.code === code) as IConceptBasic;
};

export const getHelpTextConcepts = (): IConceptBasic[] => {
    return getConceptsFromLocalStorage(help_texts_codesystem_id, HELP_TEXTS);
};

export const loadHelpTexts = async () => {
    return await loadLocalStorageItems(help_texts_codesystem_id, HELP_TEXTS);
};

export const getHelpTextPopOver  = (code : string) => {
    return  code === undefined ? "" : <HelpTextPopOver code={code}/>
}

export const getHelpTextPopOverIfAny  = (code : string | undefined, disabled : boolean) => {
    return (disabled || code === undefined) ? "" : <HelpTextPopOver code={code}/>
}