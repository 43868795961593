import React, {FC, useState} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import {IDSDialog, IDSLink} from "@inera/ids-react";
import CreateDialogForm from "./CreateDialogForm";
import {getArtifactSwedishLabel} from "../../CommonFunctions";
import TTBaseDialog from "../dialog/TTBaseDialog";

interface CreateDialogProps {
    setShowCreateDialog: any,
    showCreateDialog: boolean,
    artefactType: string,
    createButtonRef: any
}

export const CreateDialog: FC<CreateDialogProps> = ({
                                                        setShowCreateDialog,
                                                        showCreateDialog,
                                                        artefactType,
                                                        createButtonRef,
                                                    }) => {

    const [resetDialog, setResetDialog] = useState(false);
    const closeDialog = () => setShowCreateDialog(false);


    return (
        <div>
            <TTBaseDialog dialogRef={createButtonRef}
                          setShowDialog={setShowCreateDialog}
                          showDialog={showCreateDialog}
                          content={    <CreateDialogForm artifactType={artefactType} closeDialog={closeDialog}
                                                         resetDialog={resetDialog}/>}
                          headingText={"Skapa " + getArtifactSwedishLabel(artefactType)} />
        </div>
    );
};
export default CreateDialog;