import React, {FC, useEffect, useState} from "react";
import {useFieldArray, UseFormReturn} from "react-hook-form";
import {IDSCol, IDSContainer, IDSRow, IDSButton} from "@inera/ids-react";
import {IRHookSelectOption} from "../../../common/rhookform/IRHookSelectOption";
import ITelecom from "./ITelecom";
import Telecom from "./Telecom";
import {telecomEmpty} from "../../../../model/defaultvalues/MetaDataDefaultFormValue";

let renderCount = 0;


interface TelecomArrayProps {
    parentParameters: string,
    methods: UseFormReturn,
    editDisabled: boolean,
    handleWarnMessageList: any,
    handleErrorMessageList: any,
    removeValidationMessagesOnPath: any
}

const email = "email";
const phone = "phone";
const url = "url";

const contactPointSystemOptions: IRHookSelectOption[] = [
    {label: email, value: email},
    {label: "telefon", value: phone},
    {label: url, value: url}
];


const TelecomArray: FC<TelecomArrayProps> = ({
                                                 parentParameters,
                                                 methods,
                                                 editDisabled,
                                                 handleWarnMessageList,
                                                 handleErrorMessageList,
                                                 removeValidationMessagesOnPath
                                             }: TelecomArrayProps) => {

    renderCount++;
    const arrayname = parentParameters + `.telecom`;

    const {fields, append, update, remove} = useFieldArray({
        name: arrayname
    });

    const getShowAddTelecomButton = (): boolean => {
        let show = true;
        const telecomArray: ITelecom[] = methods.getValues(arrayname);
        if (telecomArray !== undefined) {
            if (telecomArray.length == contactPointSystemOptions.length) {
                show = false;
            } else {
                for (let telecom of telecomArray) {
                    if (telecom.system === "") {
                        show = false;
                        break;
                    }
                }
            }
        }
        return show;
    }

    const [showAddTelecomButton, setShowAddTelecomButton] = useState(getShowAddTelecomButton());

    useEffect(() => {
        console.log("TelecomArray arrayname " + arrayname + ", telecom renderCount: " + renderCount)
        setShowAddTelecomButton(getShowAddTelecomButton());
    }, [methods.getValues(arrayname)])


    const revaluateShowAddTelecomButton = () => {
        const newShowAddTelecomButtonStatus = getShowAddTelecomButton();
        if (showAddTelecomButton != newShowAddTelecomButtonStatus) {
            setShowAddTelecomButton(newShowAddTelecomButtonStatus);
        }
    }

    return (

        <div>
            {/*            {getRenderCountText(renderCount)} <br/>*/}
            {fields.map((field, index) => {
                return (
                    <section key={field.id}>
                        <Telecom
                            arrayname={arrayname}
                            index={index}
                            methods={methods}
                            editDisabled={editDisabled}
                            handleWarnMessageList={handleWarnMessageList}
                            handleErrorMessageList={handleErrorMessageList}
                            removeValidationMessagesOnPath={removeValidationMessagesOnPath}
                            append={append}
                            update={update}
                            remove={remove}
                            onChangeTelecomHandleArrayView={revaluateShowAddTelecomButton}/>
                    </section>
                );
            })}
            {(!editDisabled && showAddTelecomButton) &&
                <div className={'container'}>
                    <IDSContainer gutterless={true}>
                        <IDSRow justify="space-between" align="end">
                            <IDSCol cols="6">
                            </IDSCol>
                            <IDSCol cols="6">
                                <div className={'container'}>
                                    <IDSButton size='s' secondary type="button">
                                        <button type="button" onClick={() => {
                                            append(telecomEmpty);
                                        }}>
                                            LÄGG TILL KONTAKTUPPGIFT
                                        </button>
                                    </IDSButton>
                                    <br/><br/>
                                </div>

                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                </div>
            }
        </div>
    );
}
export default TelecomArray;