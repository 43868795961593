import Client from "fhir-kit-client";
import {fhirUrl} from "../model/defaultvalues/Constant";
import {getSectorTagCodeFromArtefact} from "./localstorageconcepts/SectorService";

const fhirClient = new Client({baseUrl: fhirUrl});


export const retrieveVersionHistory = (data, resourceType, setFoundHistory) => {

    if (data !== undefined && data.title !== undefined) {

        let params = {};
        params["url"] = data.url;
        params["_sort"] = "-version,-_lastUpdated";
        params["_elements"] = "title,name,version,status,date,extension";
        fhirClient
            .search({resourceType: resourceType, searchParams: params})
            .then((data) => {
                if (data.entry !== undefined && data.entry !== null) {
                    setFoundHistory(data.entry);
                } else {
                    return setFoundHistory([]);
                }
            });
    }
};


export const retrieveVersionHistoryOnSystemName = (name, resourceType, setFoundHistory) => {

    if (name !== undefined) {
        let params = {};
        params["name"] = name;
        params["_sort"] = "-version,-_lastUpdated";
        fhirClient
            .search({resourceType: resourceType, searchParams: params})
            .then((data) => {
                if (data.entry !== undefined && data.entry !== null) {
                    setFoundHistory(data.entry);
                } else {
                    return setFoundHistory([]);
                }
            });
    }
};

