import {useFieldArray, useFormContext} from "react-hook-form";
import {getRenderCountText} from '../../../common/RenderCount';
import React, {FC, useEffect, useState} from "react";
import {getExtensionIndexForEmail} from "./EmailUtilForExtension";
import {extensionAuthorCodeSystemUrl} from "../../../../model/defaultvalues/Constant";
import RHookFormTextFieldForExtension from "../../../common/rhookform/RHookFormTextFieldForExtension";
import {IDSContainer, IDSCol, IDSRow} from "@inera/ids-react";
import {metaDataTabName} from "../../../common/page/TabName";
import TelecomArray from "./TelecomArray";
import {
    removeValidationInternalMetaDataWarningFromValidationCollectionList
} from "../../../common/validation/ValidationInternalMetaDataWarningRulesUtil";
import {HELP_META_INNEHALLSANSVARIG} from "../../../../constants/HelpTextCodeConstants";
import {getHelpTextPopOverIfAny} from "../../../../services/localstorageconcepts/HelpTextService";

let renderCount = 0;

interface AuthorProps {
    editDisabled: boolean,
    authorNameValidation: any,
    handleWarnMessageList: any,
    handleErrorMessageList: any,
    removeValidationMessagesOnPath: any
}


const Author: FC<AuthorProps> = ({
                                     editDisabled,
                                     authorNameValidation,
                                     handleWarnMessageList,
                                     handleErrorMessageList,
                                     removeValidationMessagesOnPath
                                 }) => {
    renderCount++;

    const methods = useFormContext();
    const [extensionFieldIndex, setExtensionFieldIndex] = useState<any>(undefined);
    const {fields, append, remove} = useFieldArray({
        name: `extension`,
    });
    const tabName = metaDataTabName;


    // trigger on component mount
    useEffect(() => {
        const currenIndexForAuthorContact = getExtensionIndexForEmail(methods, extensionAuthorCodeSystemUrl, fields);
        if (currenIndexForAuthorContact !== extensionFieldIndex) {
            setExtensionFieldIndex(currenIndexForAuthorContact);
        }
    }, [methods.getValues("extension")]);

    const fieldNameForAuthorName = "extension." + extensionFieldIndex + ".valueContactDetail.name";
    const authorExtensionValueContactDetail = "extension." + extensionFieldIndex + ".valueContactDetail";

    useEffect(() => {
        if (tabName !== undefined) {
            removeValidationInternalMetaDataWarningFromValidationCollectionList(fieldNameForAuthorName, handleWarnMessageList, methods, tabName, undefined)
        }
        // @ts-ignore
    }, [methods.formState.errors?.["extension"]?.[extensionFieldIndex]?.["valueContactDetail"]?.["name"]]);


    return (
        <IDSContainer gutterless={true}>
            <IDSRow justify="space-between">
                <IDSCol cols="12">
                    <h3 className="ids-heading-s">Innehållsansvarig{getHelpTextPopOverIfAny(HELP_META_INNEHALLSANSVARIG, editDisabled)}</h3>
                    <br/>
                </IDSCol>
            </IDSRow>
            <IDSRow justify="space-between">
                <IDSCol cols="6">
                    <RHookFormTextFieldForExtension methods={methods}
                                                    label="Namn på innehållsansvarig (obligatorisk)"
                                                    name={fieldNameForAuthorName}
                                                    editDisabled={editDisabled}
                                                    warnFunction={authorNameValidation}
                                                    handleWarnMessageList={handleWarnMessageList}
                                                    tabName={tabName}/>
                </IDSCol>
                <IDSCol cols="6">
                </IDSCol>
            </IDSRow>
            <IDSRow justify="space-between">
                <IDSCol cols="12">
                    {/* reason for (extensionFieldIndex !== undefined) is:
               usefieldarray property "name": ...Do not support dynamic name.
               https://react-hook-form.com/docs/usefieldarray */}
                    {(extensionFieldIndex !== undefined) &&
                        <TelecomArray parentParameters={authorExtensionValueContactDetail}
                                      methods={methods}
                                      editDisabled={editDisabled}
                                      handleWarnMessageList={handleWarnMessageList}
                                      handleErrorMessageList={handleErrorMessageList}
                                      removeValidationMessagesOnPath={removeValidationMessagesOnPath}/>
                    }
                </IDSCol>
            </IDSRow>
        </IDSContainer>
    )
        ;
}
export default Author;


