import React, {FC} from 'react';

import {Tooltip,} from '@mui/material';
import {withStyles} from "@material-ui/core/styles";

let renderCount = 0;

interface TableCellPopOverProps {
    text: string,
    title: string,
    idsIcon: any
}

export const TableCellTooltip: FC<TableCellPopOverProps> = ({
                                                                text,
                                                                title,
                                                                idsIcon
                                                            }) => {
    renderCount++;

    const TooltipDiv = withStyles({
        tooltip: {
            color: "#353636",
            backgroundColor: "#FFF",
            border: '1px solid #727373',
            fontSize: "0.875rem",
            fontFamily: "Open Sans"
        }
    })(Tooltip);


    return (<>
            &nbsp;
            <TooltipDiv title={
                <>
                    <h3 style={{fontSize: "0.875rem"}}>{title}</h3>
                    {text}
                </>
            }>
                <div style={{marginBottom: "20px"}}>
                    {idsIcon}
                </div>
            </TooltipDiv>
        </>
    );
};

export default TableCellTooltip;