import React, {useState} from "react";
import {
    IDSButtonGroup,
    IDSCard,
    IDSCol,
    IDSContainer,
    IDSInput,
    IDSLink,
    IDSRow,
    IDSSelect
} from "@inera/ids-react";
import {grayBorder} from "../../../styles/TTStyles";
import {publicationStatusOptions} from "./SearchFilterCommons";
import useCollapse from "react-collapsed";
import {sectorOptions} from "../../../services/localstorageconcepts/SectorService";
import {getHelpTextPopOver} from "../../../services/localstorageconcepts/HelpTextService";
import {
    HELP_SEARCH_FILTER_FORVALTARE,
    HELP_SEARCH_FILTER_SECTOR,
    HELP_SEARCH_FILTER_VERSION, HELP_SEARCH_FILTER_VERSION_STATUS
} from "../../../constants/HelpTextCodeConstants";


let renderCount = 0;


export default function SearchArtifactChoiceAndFilter(props: any) {
    const [isExpanded, setExpanded] = useState(false);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded})


    const clearFilter = () => {
        props.clearFilters();
    };

    const displayFilterLink = () => {

        if (!props.hideFilter) {
            return (
                <IDSLink underlined {...getToggleProps({
                    style: {display: "block"},
                    onClick: () => setExpanded((x) => !x)
                })}>
                    {isExpanded ? <a>GÖM FILTER</a> : <a>VISA FILTER</a>}
                </IDSLink>

            );
        }
    };


    renderCount++;

    return (

        <IDSRow>
            <IDSCol>
                {/*<div className={'container'}>*/}
                <br/>
                <IDSCard style={grayBorder}>

                    <IDSContainer gutterless={true}>
                        <IDSRow>
                            <IDSCol cols="9">
                            </IDSCol>
                            <IDSCol cols="3">
                                <IDSButtonGroup>
                                    {displayFilterLink()}

                                    <IDSLink underlined>
                                        <a href="#" onClick={() => {
                                            clearFilter();
                                        }}>RENSA FILTER </a>
                                    </IDSLink>
                                </IDSButtonGroup>
                            </IDSCol>
                        </IDSRow>
                    </IDSContainer>
                    <br/>
                    <div {...getCollapseProps({})}>
                        <IDSContainer gutterless={true}>
                            <IDSRow>
                                <IDSCol cols="6">
                                    <div className={'container'}>
                                        <IDSSelect>
                                            <label>Sektor {getHelpTextPopOver(HELP_SEARCH_FILTER_SECTOR)}</label>
                                            <select onChange={props.onChangeSector} value={props.sector}>
                                                <option value="" disabled={false} selected={true}></option>
                                                {sectorOptions.map((item) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </IDSSelect>
                                    </div>
                                </IDSCol>
                                <IDSCol cols="6">
                                    <div className={'container'}>
                                        <IDSInput>
                                            <label>Förvaltare {getHelpTextPopOver(HELP_SEARCH_FILTER_FORVALTARE)}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={props.publisher}
                                                onChange={props.onChangePublisher}
                                            />
                                        </IDSInput>
                                    </div>
                                </IDSCol>
                            </IDSRow>
                            <IDSRow>
                                <IDSCol cols="4">
                                    <br/>
                                    <div className={'container'}>
                                        <IDSInput> <label>Version {getHelpTextPopOver(HELP_SEARCH_FILTER_VERSION)}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={props.version}
                                                onChange={props.onChangeVersion}
                                            /></IDSInput>
                                    </div>
                                </IDSCol>
                                <IDSCol cols="4">
                                    <br/>
                                    <div className={'container'}>
                                        <IDSSelect>
                                            <label>Versionsstatus {getHelpTextPopOver(HELP_SEARCH_FILTER_VERSION_STATUS)}</label>
                                            <select onChange={props.onChangeStatus} value={props.status}>
                                                <option value="" disabled={false} selected={true}></option>
                                                {publicationStatusOptions.map((item) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </IDSSelect>
                                    </div>
                                </IDSCol>
                                <IDSCol cols="4">
                                </IDSCol>
                            </IDSRow>
                        </IDSContainer>
                    </div>
                </IDSCard>
                {/*   </div>*/}
            </IDSCol>
        </IDSRow>

    );
}
