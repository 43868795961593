import React, {FC} from "react";
import '@inera/ids-design/themes/inera-admin/inera-admin.css';
import TTBaseDialog from "../dialog/TTBaseDialog";
import SectorDialogContent from "./SectorDialogContent";

interface SectorDialogProps {
    setShowDialog: any,
    showDialog: boolean,
    createButtonRef: any,
    currentUser: any,
    selectSector: any
}

export const SectorDialog: FC<SectorDialogProps> = ({
                                                                      setShowDialog,
                                                                      showDialog,
                                                                      createButtonRef,
                                                                      currentUser,
                                                                      selectSector,
                                                                  }) => {

    return (
        <div>
            <TTBaseDialog dialogRef={createButtonRef}
                          setShowDialog={setShowDialog}
                          showDialog={showDialog}
                          content={<SectorDialogContent selectSector={selectSector} currentUser={currentUser}/>}
                          headingText={"Välj sektor"}/>
        </div>
    );
};
export default SectorDialog;