import React from "react";
import {global_alerts_codesystem_id} from "../../model/defaultvalues/Constant";
import {GLOBAL_ALERTS} from "../../constants";
import {getConceptsFromLocalStorage, loadLocalStorageItems} from "./ConceptLStorageBaseServicel";
import IConceptBasic from "../../model/IConceptBasic";

export const loadGlobalAlerts = async () => {
    return await loadLocalStorageItems(global_alerts_codesystem_id, GLOBAL_ALERTS);
};

const getGlobalAlertConcepts = (): IConceptBasic[] => {
    let globalAlertsArrayFromLocalStorage: IConceptBasic[] = getConceptsFromLocalStorage(global_alerts_codesystem_id, GLOBAL_ALERTS);
    let arrayToReturn : IConceptBasic[] = [];
    for(let alert of globalAlertsArrayFromLocalStorage){
        if(alert.display !== undefined && alert.display.trim() !== ""){
            arrayToReturn.push(alert);
        }
    }
    return arrayToReturn;
};

export const globalAlerts: IConceptBasic[] = getGlobalAlertConcepts();